import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const AccountSettings = () => {
  return (
    <>
      <div className="content-inner">
        <Container>
          <Tab.Container defaultActiveKey="second">
            <Row>
              <Col lg="12">
                <Card>
                  <Card.Body className="p-0">
                    <div>
                      <Nav
                        as="ul"
                        variant="pills"
                        className="iq-edit-profile row mb-0"
                      >
                        <Nav.Item as="li" className="col-md-3 p-0">
                          <Nav.Link eventKey="second" role="button">
                            Change Password
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="col-md-3 p-0">
                          <Nav.Link eventKey="third" role="button">
                            Email and SMS
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="col-md-3 p-0">
                          <Nav.Link eventKey="fourth" role="button">
                            Manage Contact
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="second" className="fade show">
                    <Card>
                      <Card.Header className="d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Change Password</h4>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Form>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="cpass" className="form-label">
                              Current Password:
                            </Form.Label>
                            <Link to="/auth/recoverpw" className="float-end">
                              Forgot Password
                            </Link>
                            <Form.Control
                              type="Password"
                              className="form-control"
                              id="cpass"
                              defaultValue=""
                            />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="npass" className="form-label">
                              New Password:
                            </Form.Label>
                            <Form.Control
                              type="Password"
                              className="form-control"
                              id="npass"
                              defaultValue=""
                            />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="vpass" className="form-label">
                              Verify Password:
                            </Form.Label>
                            <Form.Control
                              type="Password"
                              className="form-control"
                              id="vpass"
                              defaultValue=""
                            />
                          </Form.Group>
                          <Button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            Submit
                          </Button>{" "}
                          <button
                            type="reset"
                            className="btn btn-danger-subtle"
                          >
                            Cancel
                          </button>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third" className="fade show">
                    <Card>
                      <Card.Header className="card-header d-flex justify-content-between">
                        <div className="header-title">
                          <h4 className="card-title">Email and SMS</h4>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Form>
                          <Form.Group className="form-group row align-items-center">
                            <div
                              className="col-md-3"
                              htmlFor="emailnotification"
                            >
                              Email Notification:
                            </div>
                            <Form.Check className="col-md-9 form-check form-switch">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked11"
                                defaultChecked
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckChecked11"
                              >
                                Checked switch checkbox input
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                          <Form.Group className="form-group row align-items-center">
                            <div className="col-md-3" htmlFor="smsnotification">
                              SMS Notification:
                            </div>
                            <Form.Check className="col-md-9 form-check form-switch">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked12"
                                defaultChecked
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckChecked12"
                              >
                                Checked switch checkbox input
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                          <Form.Group className="form-group row align-items-center">
                            <div className="col-md-3" htmlFor="npass">
                              When To Email
                            </div>
                            <Col md="9">
                              <Form.Check className="form-check">
                                <Form.Check.Input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault12"
                                />
                                <Form.Check.Label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault12"
                                >
                                  You have new notifications.
                                </Form.Check.Label>
                              </Form.Check>
                              <Form.Check className="form-check d-block">
                                <Form.Check.Input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="email02"
                                />
                                <Form.Check.Label
                                  className="form-check-label"
                                  htmlFor="email02"
                                >
                                  You're sent a direct message
                                </Form.Check.Label>
                              </Form.Check>
                              <Form.Check className="form-check d-block">
                                <Form.Check.Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="email03"
                                  defaultChecked
                                />
                                <Form.Check.Label
                                  className="form-check-label"
                                  htmlFor="email03"
                                >
                                  Someone adds you as a connection
                                </Form.Check.Label>
                              </Form.Check>
                            </Col>
                          </Form.Group>
                          <Form.Group className="form-group row align-items-center">
                            <div className="col-md-3" htmlFor="npass">
                              When To Escalate Emails
                            </div>
                            <Col md="9">
                              <Form.Check className="form-check">
                                <Form.Check.Input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="email04"
                                />
                                <Form.Check.Label
                                  className="form-check-label"
                                  htmlFor="email04"
                                >
                                  Upon new order.
                                </Form.Check.Label>
                              </Form.Check>
                              <Form.Check className="form-check d-block">
                                <Form.Check.Input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="email05"
                                />
                                <Form.Check.Label
                                  className="form-check-label"
                                  htmlFor="email05"
                                >
                                  New membership approval
                                </Form.Check.Label>
                              </Form.Check>
                              <Form.Check className="form-check d-block">
                                <Form.Check.Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="email06"
                                  defaultChecked
                                />
                                <Form.Check.Label
                                  className="form-check-label"
                                  htmlFor="email06"
                                >
                                  Member registration
                                </Form.Check.Label>
                              </Form.Check>
                            </Col>
                          </Form.Group>
                          <Button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            Submit
                          </Button>{" "}
                          <button
                            type="reset"
                            className="btn btn-danger-subtle"
                          >
                            Cancel
                          </button>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth" className="fade show">
                    <Card>
                      <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                          <h4 className="card-title">Manage Contact</h4>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Form>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="cno" className="form-label">
                              Contact Number:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="cno"
                              defaultValue="001 2536 123 458"
                            />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email" className="form-label">
                              Email:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="email"
                              defaultValue="Bnijone@demo.com"
                            />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="url" className="form-label">
                              Url:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="url"
                              defaultValue="https://getbootstrap.com"
                            />
                          </Form.Group>
                          <Button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            Submit
                          </Button>{" "}
                          <button
                            type="reset"
                            className="btn btn-danger-subtle"
                          >
                            Cancel
                          </button>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
                {/* </div> */}
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    </>
  );
};

export default AccountSettings;
