import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, OverlayTrigger, Badge } from "react-bootstrap";
import Card from "../../../../components/Card";
import { Link, useNavigate } from "react-router-dom";
import pageimg1 from "../../../../assets/images/page-img/43.png";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthToken, selectUserInfo } from "../../../../store/auth/auth";
import axios from "axios";
import { setGlobalNotification } from "../../../../store/notification/notification";
import { setGlobalError } from "../../../../store/error/error";

const ManageCompanies = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const userObject = useSelector(selectUserInfo);
  const [searchKey, setSearchKey] = useState("");
  const authToken = useSelector(selectAuthToken);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authToken) {
      const fetchCompaniesList = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          setUserData(response.data.data);
        } catch (error) {
          console.error("Error fetching companies:", error);
        }
      };
      fetchCompaniesList();
    }
  }, [authToken]);

  const deleteCompany = async (company) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company/${company.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // Locally Removing User From Records
      let userDataCopy = [...userData];
      userDataCopy = userDataCopy.filter((x) => x?.id != company.id);
      setUserData(userDataCopy);
      dispatch(setGlobalNotification("Company deleted successfully !"));
      //
    } catch (error) {
      console.error("Error : ", error);
      dispatch(setGlobalError("Error ! Deleting Company."));
    }
  };

  return (
    <>
      <Row>
        <Col sm="24">
          <Card>
            <Card.Body className="pt-0">
              <Row className="justify-content-between d-flex">
                <Col sm="24" md="6">
                  <div
                    id="user_list_datatable_info"
                    className="dataTables_filter"
                  >
                    <form className="me-3 position-relative">
                      <div className="form-group mb-0">
                        <input
                          type="search"
                          className="form-control"
                          id="exampleInputSearch"
                          placeholder="Search Companies"
                          value={searchKey}
                          onChange={(e) => setSearchKey(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </Col>
                <Col sm="24" md="6">
                  {/* <div className="user-list-files d-flex justify-content-end">
                    <Link
                      to="#"
                      className="chat-icon-phone btn btn-primary-subtle"
                    >
                      Print
                    </Link>{" "}
                    <Link
                      to="#"
                      className="chat-icon-video btn btn-primary-subtle"
                    >
                      Excel
                    </Link>{" "}
                    <Link
                      to="#"
                      className="cchat-icon-delete btn btn-primary-subtle"
                    >
                      Pdf
                    </Link>{" "}
                  </div> */}
                </Col>
              </Row>
              <div className="table-responsive">
                <table className="files-lists table table-striped mt-4 text-center">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Tag Line</th>
                      <th scope="col">Company Type</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Employee</th>
                      <th scope="col">Website</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData
                      .filter(
                        (x) =>
                          x?.name
                            ?.toLowerCase()
                            .includes(searchKey?.toLowerCase()) ||
                          x?.companyType
                            ?.toLowerCase()
                            .includes(searchKey?.toLowerCase()) ||
                          x?.contactEmail
                            ?.toLowerCase()
                            .includes(searchKey?.toLowerCase()) ||
                          x?.phoneNumber
                            ?.toLowerCase()
                            .includes(searchKey?.toLowerCase())
                      )
                      .map((company) => (
                        <tr>
                          <td className="text-start">
                            <img
                              className="rounded-circle img-fluid avatar-40 me-2 object-fit"
                              src={
                                company && company.companyLogoUrl
                                  ? company?.companyLogoUrl
                                  : pageimg1
                              }
                              alt="profile"
                            />{" "}
                            {company?.name}
                          </td>
                          <td>{company?.tagLine}</td>
                          <td>
                            <Badge
                              bg=""
                              bsPrefix="badge border border-primary text-primary mt-2"
                            >
                              {company?.companyType}
                            </Badge>
                          </td>
                          <td>{company?.contactEmail}</td>
                          <td>{company?.phoneNumber}</td>
                          <td>{new Date(company?.createdAt).toDateString()}</td>
                          <td>
                            <Badge
                              bg=""
                              bsPrefix="badge border border-primary text-primary mt-2"
                            >
                              {company?.numberOfEmployee}
                            </Badge>
                          </td>
                          <td>{company?.websiteUrl}</td>
                          <td>
                            <div className="flex align-items-center list-user-action lh-1">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <Link
                                  to="#"
                                  onClick={() => deleteCompany(company)}
                                  className="text-danger"
                                >
                                  <i className="material-symbols-outlined">
                                    delete
                                  </i>
                                </Link>
                              </OverlayTrigger>{" "}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ManageCompanies;
