import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  ListGroup,
  Badge,
  Carousel,
  Button,
  Card as BootstrapCard,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import user1 from "../../assets/images/user/1.jpg";
import user01 from "../../assets/images/user/01.jpg";
import user13 from "../../assets/images/user/05.jpg";
import user5 from "../../assets/images/page-img/fun.webp";

import boyImg from "../../assets/images/page-img/boy.webp";
import busImg from "../../assets/images/page-img/bus.webp";
import img11 from "../../assets/images/page-img/fd.webp";
import mountain from "../../assets/images/page-img/mountain.webp";
import pizza from "../../assets/images/page-img/pizza.webp";

// FsLightbox
import ReactFsLightbox from "fslightbox-react";

import { useDispatch, useSelector } from "react-redux";
import {
  selectAuthToken,
  selectUserInfo,
  setAuth,
} from "../../store/auth/auth";
import axios from "axios";
import CreateEventDialog from "./modals/create-event";
import CreateNewsDialog from "./modals/create-news";
import { timeAgo } from "../../utilities/helpers";

const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;

const Index = () => {
  const [loadContent, setLoadContent] = useState(true);
  const history = useNavigate();
  const dispatch = useDispatch();
  const userObject = useSelector(selectUserInfo);
  const [events, setEvents] = useState([]);
  const [news, setNews] = useState([]);
  const [stats, setStats] = useState(null);
  const authToken = useSelector(selectAuthToken);
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  useEffect(() => {
    function handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setTimeout(() => {
          setLoadContent(false);
        }, 2000);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/event/${userObject?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setEvents(response.data.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchNews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/news/${userObject?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setNews(response.data.data);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const fetchMonthlyStats = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/general`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setStats(response.data?.data?.[0]);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const likeNewsFeed = async (newsId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/user/like`,
        { userId: userObject?.id, newsId: newsId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let newsDataCopy = [...news];
      let index = newsDataCopy.findIndex((newsObj) => newsObj?.id == newsId);
      newsDataCopy[index].likesCount =
        Number(newsDataCopy[index]?.likesCount) + response.data.data;
      setNews(newsDataCopy);
    } catch (error) {
      console.error("Error liking news:", error);
    }
  };

  const getUserDataById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/user/${userObject?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      dispatch(
        setAuth({
          userInfo: response.data.data,
          authToken: authToken,
        })
      );
    } catch (error) {
      console.error("Error liking news:", error);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchEvents();
      fetchNews();
      fetchMonthlyStats();
      getUserDataById();
    }
  }, [authToken]);

  const navigateToSettings = () => {
    history("settings/profile");
  };

  return (
    <>
      <div id="content-page" className="custom-container">
        <FsLightbox
          toggler={imageController.toggler}
          sources={[user5, boyImg, busImg, img11, mountain, pizza]}
          slide={imageController.slide}
        />
        <div>
          <Row className="gx-4">
            {/* User Profile Information */}
            <Col lg={3}>
              <Card>
                <Card.Body>
                  <div className="text-center">
                    <img
                      loading="lazy"
                      className="img-fluid rounded-circle avatar-130 border-4 border-primary object-fit-cover"
                      src={
                        userObject && userObject?.profilePictureUrl
                          ? userObject.profilePictureUrl
                          : "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352010-stock-illustration-default-placeholder-man-and-woman.jpg"
                      }
                      alt="profile-img"
                      style={{ borderStyle: "solid" }}
                      onClick={navigateToSettings}
                    />
                    {userObject?.firstName && (
                      <h4 className="mt-2">
                        {userObject ? userObject.firstName : ""}{" "}
                        {userObject ? userObject.lastName : ""}
                      </h4>
                    )}
                    <Link to="#">{`@${
                      userObject ? userObject.firstName.toLowerCase() : ""
                    }_${
                      userObject ? userObject.lastName.toLowerCase() : ""
                    }`}</Link>
                    <p>{userObject ? userObject.currentRole : ""}</p>
                    <Link
                      className="p-image d-flex align-items-center justify-content-center"
                      style={{ top: "8px" }}
                      to="/dashboard/settings/profile"
                    >
                      <span className="material-symbols-outlined">edit</span>
                    </Link>
                    {userObject?.membershipType == 0 ? (
                      <Badge
                        bg=""
                        bsPrefix="badge border border-success text-success mt-2"
                        style={{
                          top: "2px",
                          position: "absolute",
                          left: "10px",
                        }}
                      >
                        Freemium
                      </Badge>
                    ) : (
                      <Badge
                        bg=""
                        bsPrefix="badge border border-warning text-warning mt-2"
                        style={{
                          top: "2px",
                          position: "absolute",
                          left: "10px",
                        }}
                      >
                        Gold
                      </Badge>
                    )}
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <p className="mb-0">Followers</p>
                    <p className="mb-0">Following</p>
                    <p className="mb-0">Companies</p>
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <h6 className="mb-0 fw-bold">{userObject?.followersCount}</h6>
                    <h6 className="mb-0 fw-bold">{userObject?.usersFollowedCount}</h6>
                    <h6 className="mb-0 fw-bold">{userObject?.companiesFollowedCount}</h6>
                  </div>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">My Company</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="text-primary">
                      Manage My Company
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Recommendation</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="text-primary">
                      My list
                    </ListGroup.Item>
                    <ListGroup.Item className="text-primary">
                      My Saved Searches
                    </ListGroup.Item>
                    <ListGroup.Item className="text-primary">
                      Import Lists
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
              {/* <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Explore</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item className="text-primary">
                      Search Companies
                    </ListGroup.Item>
                    <ListGroup.Item className="text-primary">
                      Search Contacts
                    </ListGroup.Item>
                    <ListGroup.Item className="text-primary">
                      Discover Hubs
                    </ListGroup.Item>
                    <ListGroup.Item className="text-primary">
                      Find Investors
                    </ListGroup.Item>
                    <ListGroup.Item className="text-primary">
                      Create Profile
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card> */}
            </Col>

            {/* Dashboard Newsfeed */}
            <Col lg={6}>
              <div id="content">
                <Row className="special-post-container">
                  <Col sm={12} className="special-post">
                    <BootstrapCard className="mb-3">
                      <Row className="row no-gutters flex-row-reverse">
                        <Col md={4}>
                          <BootstrapCard.Img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvOj-By3tKcbify3542FJBioc3wuuTqAod2Q&s"
                            alt="#"
                          />
                        </Col>
                        <Col md={8}>
                          <BootstrapCard.Body className="text-right">
                            <BootstrapCard.Title as="h4">
                              Hey {userObject ? userObject.firstName : ""},
                            </BootstrapCard.Title>
                            <BootstrapCard.Text>
                              Now, Qaflah's all-in-one prospecting solution is
                              available on LinkedIn, LinkedIn Sales Navigator,
                              Salesforce and company websites.
                            </BootstrapCard.Text>
                          </BootstrapCard.Body>
                        </Col>
                      </Row>
                    </BootstrapCard>
                  </Col>
                  {news.map((news) => (
                    <Col sm={12}>
                      <BootstrapCard>
                        <BootstrapCard.Header className="d-flex justify-content-between">
                          <div className="header-title w-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="text-danger font-size-14">News</p>
                              <p className="font-size-14">
                                {timeAgo(news.createdAt)}
                              </p>
                            </div>
                            <h4 className="card-title">{news.title}</h4>
                          </div>
                        </BootstrapCard.Header>
                        <BootstrapCard.Body className="pt-0">
                          <div className="bd-example">
                            <p>{news.description}</p>
                            {news.newsBannerUrl && (
                              <img
                                src={news.newsBannerUrl}
                                className="d-block w-100"
                                alt="#"
                                style={{
                                  width: "100%",
                                  maxHeight: "300px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </div>
                        </BootstrapCard.Body>
                        <BootstrapCard.Footer>
                          <div className="d-flex justify-content-between align-items-center">
                            {news.link && (
                              <Button
                                variant="success-subtle"
                                className="btn d-inline-flex btn-success-subtle me-1"
                                onClick={() =>
                                  window.location.replace(news.link)
                                }
                              >
                                <i className="material-symbols-outlined me-1">
                                  visibility
                                </i>
                                Read More
                              </Button>
                            )}
                            {news?.likesCount && (
                              <Button
                                variant="primary-subtle"
                                className="btn d-inline-flex btn-primary-subtle me-1"
                                onClick={() => likeNewsFeed(news?.id)}
                              >
                                <i className="material-symbols-outlined me-1">
                                  favorite
                                </i>
                                {Number(news?.likesCount) > 0
                                  ? news.likesCount
                                  : ""}{" "}
                                Like
                              </Button>
                            )}
                          </div>
                        </BootstrapCard.Footer>
                      </BootstrapCard>
                    </Col>
                  ))}
                  {events.map((event) => (
                    <Col sm={12}>
                      <BootstrapCard>
                        <BootstrapCard.Header className="d-flex justify-content-between">
                          <div className="header-title w-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="text-primary font-size-14">Event</p>
                              <p className="font-size-14">
                                {timeAgo(event.createdAt)}
                              </p>
                            </div>
                            <h4 className="card-title">{event.name}</h4>
                          </div>
                        </BootstrapCard.Header>
                        <BootstrapCard.Body className="pt-0">
                          <div className="bd-example">
                            <p>{event.longDescription}</p>
                            {event.eventBannerImage && (
                              <Carousel
                                id="carouselExampleSlidesOnly"
                                indicators={true}
                                data-ride="carousel"
                              >
                                <Carousel.Item>
                                  <img
                                    src={event.eventBannerImage}
                                    className="d-block w-100"
                                    alt="#"
                                  />
                                  <Carousel.Caption className="bg-dark">
                                    <h5 className="text-light">{event.name}</h5>
                                    <p className="text-light">
                                      {event.shortDescription}
                                    </p>
                                  </Carousel.Caption>
                                </Carousel.Item>
                              </Carousel>
                            )}
                          </div>
                        </BootstrapCard.Body>
                        <BootstrapCard.Footer>
                          <div className="d-flex justify-content-start align-items-center">
                            {event.eventUrl && (
                              <Button
                                variant="success-subtle"
                                className="btn d-inline-flex btn-success-subtle me-1"
                                onClick={() =>
                                  window.location.replace(event.eventUrl)
                                }
                              >
                                <i className="material-symbols-outlined me-1">
                                  visibility
                                </i>
                                Read More
                              </Button>
                            )}
                            {event.registrationUrl && (
                              <Button
                                variant="primary-subtle"
                                className="btn d-inline-flex btn-primary-subtle me-1"
                                onClick={() =>
                                  window.location.replace(event.registrationUrl)
                                }
                              >
                                <i className="material-symbols-outlined me-1">
                                  <span class="material-symbols-outlined">
                                    edit_calendar
                                  </span>
                                </i>
                                Register
                              </Button>
                            )}
                          </div>
                        </BootstrapCard.Footer>
                      </BootstrapCard>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>

            {/* Suggestion / Recommendation */}
            <Col lg={3}>
              <Row>
                <Col sm="12" lg="12">
                  <Card>
                    <Card.Body className="pt-4">
                      <h4 className="card-title">New Profiles</h4>
                      <p className="mb-3">
                        Add new profiles to Qaflah by selecting below available
                        options
                      </p>
                      <div className="d-grid gap-2">
                        <Link
                          variant="outline-primary"
                          to="/dashboard/company/create"
                        >
                          Company / Organization
                        </Link>
                        <CreateEventDialog />
                        <CreateNewsDialog />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {stats ? (
                <Row as="ul" className="list-unstyled mb-0">
                  <Col sm="6" as="li">
                    <Card>
                      <Card.Body>
                        <div className="points text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <h3 className="fw-bolder text-danger font-size-20">
                              {stats.fundingRound}
                            </h3>
                          </div>
                          <span>Funding Round</span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="6" as="li">
                    <Card>
                      <Card.Body>
                        <div className="points text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <h3 className="fw-bolder text-danger font-size-20">
                              {stats.totalFunding}
                            </h3>
                          </div>
                          <span>Total Fundings</span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="6" as="li">
                    <Card>
                      <Card.Body>
                        <div className="points text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <h3 className="fw-bolder text-danger font-size-20">
                              {stats.aquisition}
                            </h3>
                          </div>
                          <span>Aquistion</span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="6" as="li">
                    <Card>
                      <Card.Body>
                        <div className="points text-center">
                          <div className="d-flex align-items-center justify-content-center">
                            <h3 className="fw-bolder text-danger font-size-20">
                              {stats.amount}
                            </h3>
                          </div>
                          <span>Amount</span>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <></>
              )}

              <div className="fixed-suggestion mb-0 mb-lg-4">
                <Card>
                  <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">
                        Featured Searches and Lists
                      </h4>
                    </div>
                  </div>
                  <Card.Body className="pt-0">
                    <ul className="list-inline m-0 p-0">
                      <li className="d-flex mb-3 pb-3 border-bottom">
                        <div className="stories-data ms-3">
                          <h6 className="font-size-14">
                            Embedded Fintech 50{" "}
                            <span className="fw-normal text-body">
                              - 2023 Honorees - by GGV Capital
                            </span>{" "}
                          </h6>
                        </div>
                      </li>
                      <li className="d-flex mb-3 pb-3 border-bottom">
                        <div className="stories-data ms-3">
                          <h6 className="font-size-14">
                            Fintech Companies with a Recent Leadership Hire
                          </h6>
                        </div>
                      </li>
                      <li className="d-flex mb-3 pb-3 border-bottom">
                        <div className="stories-data ms-3">
                          <h6 className="font-size-14">
                            Leading Edtech Through COVID-19
                          </h6>
                          <small className="text-capitalize">1 month ago</small>
                        </div>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header>
                    <div className="header-title">
                      <h4 className="card-title">Recently Viewed</h4>
                    </div>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <ul className="request-list list-inline m-0 p-0">
                      <li className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="user-img img-fluid flex-shrink-0">
                            <img
                              src={user13}
                              alt="story-img"
                              className="rounded-circle avatar-40"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6>NVIDIA</h6>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2 mt-md-0">
                          <div className="confirm-click-btn">
                            <Link
                              to="#"
                              className="me-2 btn btn-success-subtle rounded confirm-btn p-1 lh-1"
                            >
                              <i className="material-symbols-outlined font-size-14">
                                done
                              </i>
                            </Link>
                            <Link
                              to="#"
                              className="me-3 btn btn-primary rounded request-btn"
                              style={{ display: "none" }}
                            >
                              View All
                            </Link>
                          </div>
                          <Link
                            to="#"
                            className="btn btn-danger-subtle rounded p-1 lh-1"
                            data-extra-toggle="delete"
                            data-closest-elem=".item"
                          >
                            <i className="material-symbols-outlined font-size-14">
                              close
                            </i>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header>
                    <div className="header-title">
                      <h4 className="card-title">Trending Profiles</h4>
                    </div>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <ul className="request-list list-inline m-0 p-0">
                      <li className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="user-img img-fluid flex-shrink-0">
                            <img
                              src={user01}
                              alt="story-img"
                              className="rounded-circle avatar-40"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6>Platform Science</h6>
                            {/* <p className="mb-0">40K Followers</p> */}
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2 mt-md-0">
                          <div className="confirm-click-btn">
                            <Link
                              to="#"
                              className="me-2 btn btn-success-subtle rounded confirm-btn p-1 lh-1"
                            >
                              <i className="material-symbols-outlined font-size-14">
                                done
                              </i>
                            </Link>
                            <Link
                              to="#"
                              className="me-3 btn btn-primary rounded request-btn"
                              style={{ display: "none" }}
                            >
                              View All
                            </Link>
                          </div>
                          <Link
                            to="#"
                            className="btn btn-danger-subtle rounded p-1 lh-1"
                            data-extra-toggle="delete"
                            data-closest-elem=".item"
                          >
                            <i className="material-symbols-outlined font-size-14">
                              close
                            </i>
                          </Link>
                        </div>
                      </li>
                      <li className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="user-img img-fluid flex-shrink-0">
                            <img
                              src={user1}
                              alt="story-img"
                              className="rounded-circle avatar-40"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6>Cognosys</h6>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2 mt-md-0">
                          <div className="confirm-click-btn">
                            <Link
                              to="#"
                              className="me-2 btn btn-success-subtle rounded confirm-btn p-1 lh-1"
                            >
                              <i className="material-symbols-outlined font-size-14">
                                done
                              </i>
                            </Link>
                            <Link
                              to="#"
                              className="me-3 btn btn-primary rounded request-btn"
                              style={{ display: "none" }}
                            >
                              View All
                            </Link>
                          </div>
                          <Link
                            to="#"
                            className="btn btn-danger-subtle rounded p-1 lh-1"
                            data-extra-toggle="delete"
                            data-closest-elem=".item"
                          >
                            <i className="material-symbols-outlined font-size-14">
                              close
                            </i>
                          </Link>
                        </div>
                      </li>
                      <li className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="user-img img-fluid flex-shrink-0">
                            <img
                              src={user13}
                              alt="story-img"
                              className="rounded-circle avatar-40"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6>LangChain</h6>
                            {/* <p className="mb-0">40K Followers</p> */}
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2 mt-md-0">
                          <div className="confirm-click-btn">
                            <Link
                              to="#"
                              className="me-2 btn btn-success-subtle rounded confirm-btn p-1 lh-1"
                            >
                              <i className="material-symbols-outlined font-size-14">
                                done
                              </i>
                            </Link>
                            <Link
                              to="#"
                              className="me-3 btn btn-primary rounded request-btn"
                              style={{ display: "none" }}
                            >
                              View All
                            </Link>
                          </div>
                          <Link
                            to="#"
                            className="btn btn-danger-subtle rounded p-1 lh-1"
                            data-extra-toggle="delete"
                            data-closest-elem=".item"
                          >
                            <i className="material-symbols-outlined font-size-14">
                              close
                            </i>
                          </Link>
                        </div>
                      </li>
                      <li className="d-block text-center">
                        <Link to="#" className="me-3 btn btn-primary">
                          View All
                        </Link>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Index;
