import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Dropdown,
  Badge,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { selectAuthToken } from "../../../store/auth/auth";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import axios from "axios";
import { useForm } from "react-hook-form";

const SearchCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const authToken = useSelector(selectAuthToken);
  const { register, handleSubmit } = useForm();
  const categories = {
    founderCategory: [
      "All founders category",
      "Black-founded",
      "Hispanic & Latino-founded",
      "Women-founded",
      "Indigenous-founded",
      "Veteran-founded",
      "Asian-founded",
      "Disability-founded",
      "Immigrant-founded",
      "Minority-founded",
      "First-generation-founded",
      "Youth-founded",
      "Senior-founded",
    ],
    companyType: ["All types", "For Profit", "Non-profit"],
    industry: [
      "All industries",
      "Technology",
      "Healthcare",
      "Finance",
      "Manufacturing",
      "Education",
      "Retail",
      "Energy",
      "Real Estate",
    ],
    region: [
      "Anywhere",
      "America / Canada",
      "Remote",
      "Europe",
      "South Asia",
      "Latin America",
      "Southeast Asia",
      "Africa",
      "Middle East and North Africa",
      "East Asia",
      "Oceania",
    ],
  };

  const generateQueryParams = (data) => {
    const queryParams = [];
    if (data.founderCategory && !data?.founderCategory?.includes("All")) {
      queryParams.push(
        `founderCategory=${encodeURIComponent(data.founderCategory)}`
      );
    }
    if (data.companyType && !data?.companyType?.includes("All")) {
      queryParams.push(`companyType=${encodeURIComponent(data.companyType)}`);
    }
    if (data.industry && !data?.industry?.includes("All")) {
      queryParams.push(`industryType=${encodeURIComponent(data.industry)}`);
    }
    if (data.region && !data?.region?.includes("Anywhere")) {
      queryParams.push(`region=${encodeURIComponent(data.region)}`);
    }
    if (data.companySize) {
      queryParams.push(`companySize=${data.companySize}`);
    }
    return queryParams.length > 0 ? "?" + queryParams.join("&") : "";
  };

  useEffect(() => {
    if (authToken) {
      setLoading(true);
      const fetchCompanies = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          setCompanies(response.data.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching companies:", error);
          setLoading(false);
        }
      };
      fetchCompanies();
    }
  }, [authToken]);

  // Debounce the API call by 500ms
  const delayedQuery = useCallback(
    debounce(async (query) => {
      if (query) {
        try {
          setLoading(true);
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company/search?keyword=${query}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          setCompanies(response.data.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("Error fetching search results", error);
        }
      }
    }, 500), // 500ms delay
    []
  );

  const searchBySideFilters = async (query) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company/search${query}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setCompanies(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching search results", error);
    }
  };

  const submitSideFilterForm = (data) => {
    let queryParams = generateQueryParams(data);
    searchBySideFilters(queryParams);
  };

  useEffect(() => {
    delayedQuery(searchTerm);
    // Cancel the debounce on component unmount
    return () => {
      delayedQuery.cancel();
    };
  }, [searchTerm, delayedQuery]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const navigateToCompanyDetails = async (companyId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company/details/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Company Detail Response : ", response);
    } catch (x) {}
  };

  const renderRadioGroup = (name, options) => (
    <Card.Body className="pt-3">
      <p className="fw-bolder font-size-16">
        {name.charAt(0).toUpperCase() +
          name.slice(1).replace(/([A-Z])/g, " $1")}
      </p>
      {options.map((option, index) => (
        <Form.Check
          key={index}
          type="radio"
          id={`${name}${index}`}
          label={option}
          value={option}
          className="form-check d-block mb-2"
          {...register(name)}
        />
      ))}
    </Card.Body>
  );

  return (
    <>
      <div id="content-page" className="custom-container">
        <Row>
          <Col lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between rounded border-bottom-0">
                <div className="header-title">
                  <h4 className="card-title">Companies Directory</h4>
                </div>
              </Card.Header>
              <div className="ps-5 pe-5 pb-3">
                In this directory, you can search for companies by industry,
                region, company size, and more.
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="3" className="position-relative">
            <Form onSubmit={handleSubmit(submitSideFilterForm)}>
              <Button
                type="submit"
                variant="primary-subtle"
                size="sm"
                className="position-absolute"
                style={{ zIndex: "2", right: "20px", top: "20px" }}
              >
                <i className="material-symbols-outlined mt-2">filter_alt</i>
              </Button>
              <Card>
                {Object.entries(categories).map(([name, options], index) => (
                  <React.Fragment key={name}>
                    {renderRadioGroup(name, options)}
                    {index < Object.keys(categories).length - 1 && <hr />}
                  </React.Fragment>
                ))}
                <hr />
                <Card.Body>
                  <p className="fw-bolder font-size-16">Company Size</p>
                  <Form.Range
                    id="customRange1"
                    {...register("companySize", { valueAsNumber: true })}
                  />
                </Card.Body>
              </Card>
            </Form>
          </Col>

          <Col lg="9">
            <Card>
              <div className="ps-4 pt-4 pe-4 d-flex justify-content-between">
                <Dropdown
                  bsPrefix=" "
                  className="iq-search-bar device-search position-relative d-none d-lg-block"
                  style={{ width: "60%" }}
                >
                  <Dropdown.Toggle
                    as="form"
                    bsPrefix=" "
                    action="#"
                    className="searchbox open-modal-search"
                  >
                    <Link className="search-link" to="#">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="7.82491"
                          cy="7.82495"
                          r="6.74142"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></circle>
                        <path
                          d="M12.5137 12.8638L15.1567 15.5"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </Link>
                    <Form.Control
                      type="text"
                      className="text search-input form-control bg-light-subtle"
                      placeholder="Search for companies or organizations..."
                      value={searchTerm}
                      onChange={handleInputChange}
                    />
                    <Link className="d-lg-none d-flex d-none d-lg-block" to="/">
                      <span className="material-symbols-outlined">
                        search12
                      </span>
                    </Link>
                  </Dropdown.Toggle>
                </Dropdown>
                <p>
                  Showing {companies.length} out of {companies.length} companies
                </p>
              </div>
              <Card.Body>
                <ul>
                  {companies.map((company) => (
                    <li className="d-flex align-items-center justify-content-between pt-3 pb-3">
                      <div className="d-flex align-items-center">
                        <div className="user-img img-fluid flex-shrink-0">
                          <img
                            src={company.companyLogoUrl}
                            alt="story-img"
                            className="rounded-circle avatar-40 object-fit-cover"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6 className="font-size-18">
                            {company.name}
                            <span className="ms-2 font-size-10 text-light">
                              {company.description.length > 70
                                ? company.description.slice(0, 70) + "..."
                                : company.description}
                            </span>
                          </h6>
                          <p className="mt-1 mb-0 font-size-14">
                            {/* {company.keywords.map((keyword) => ( */}
                            <Badge
                              bg=""
                              bsPrefix="badge border border-primary text-primary mt-2 me-2"
                            >
                              {company.companyType}
                            </Badge>
                            {/* ))} */}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-2 mt-md-0">
                        <Link
                          to={`/dashboard/company/${company.id}`}
                          // onClick={() => navigateToCompanyDetails(company.id)}
                          className="me-2 btn btn-success-subtle rounded confirm-btn p-1 lh-1"
                        >
                          <i className="material-symbols-outlined font-size-20">
                            visibility
                          </i>
                        </Link>
                      </div>
                    </li>
                  ))}
                  {loading && (
                    <li className="d-flex align-items-center justify-content-center pt-3 pb-3">
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                      />
                    </li>
                  )}
                  {companies.length == 0 && !loading && (
                    <li className="d-flex align-items-center justify-content-center pt-3 pb-3 text-danger">
                      Cannot find any result for your searched criteria
                    </li>
                  )}
                </ul>
                {companies.length > 10 && (
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <Link className="page-link" to="#" tabIndex="-1">
                          Previous
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          1
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          2
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          3
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SearchCompanies;
