import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import qaflaOfficialLogo from "../../../assets/images/qaflah-icons/qaflah-logo.webp";
import getFundedIcon from "../../../assets/images/qaflah-icons/getfunded.webp";
import cofounderIcon from "../../../assets/images/qaflah-icons/cofounder.webp";
import buildProductIcon from "../../../assets/images/qaflah-icons/buildproduct.webp";
import accessIcon from "../../../assets/images/qaflah-icons/access.webp";
import SubscribeToWaitList from "../modals/subscribe-to-wait-list";
import instagram from "../../../assets/images/qaflah-icons/insta.jpg";
import facebook from "../../../assets/images/qaflah-icons/fb.jpg";
import linkedin from "../../../assets/images/qaflah-icons/in.jpg";

const ComingSoon = () => {
  return (
    <>
      <div className="iq-comingsoon mt-5 mt-lg-0">
        <Container>
          <div
            className="d-flex flex-column justify-content-center"
            style={{ height: "100vh" }}
          >
            <Row className="justify-content-center">
              <Col md="8" className="text-center">
                <div className="iq-comingsoon-info">
                  <Link
                    className="d-flex justify-content-center align-items-center gap-2 iq-header-logo"
                    to="/"
                  >
                    <img
                      src={qaflaOfficialLogo}
                      style={{ maxWidth: "250px", width: "50vw" }}
                    />
                  </Link>
                  <h4 className="mt-4 mb-2 fw-semibold">Stay tuned!</h4>
                  <p className="font-size-16 mt-1 fw-medium">
                    We're building something amazing for founders, investors and
                    the do-ers that want to dive head-first into the
                    entrepreneurial eco-system.
                  </p>
                  <div className="d-flex justify-content-center align-items-center gap-2 iq-header-logo">
                    <img
                      src={cofounderIcon}
                      style={{
                        maxWidth: "150px",
                        width: "20vw",
                        margin: "3px",
                      }}
                    />
                    <img
                      src={accessIcon}
                      style={{
                        maxWidth: "150px",
                        width: "20vw",
                        margin: "3px",
                      }}
                    />
                    <img
                      src={getFundedIcon}
                      style={{
                        maxWidth: "150px",
                        width: "20vw",
                        margin: "3px",
                      }}
                    />
                    <img
                      src={buildProductIcon}
                      style={{
                        maxWidth: "150px",
                        width: "20vw",
                        margin: "3px",
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col lg="12" md="12">
                <Form className="iq-comingsoon-form mt-5">
                  <Form.Group className="input-group mb-3 d-flex justify-content-center">
                    <SubscribeToWaitList showTextButton={true} />
                  </Form.Group>
                </Form>
                <ul
                  className="m-auto social-data-block d-flex align-items-center justify-content-between list-inline p-0 mt-4"
                  style={{ width: "100px" }}
                >
                  <li className="text-center pe-3">
                    <Link
                      to="https://facebook.com/joinqaflah"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        loading="lazy"
                        src={facebook}
                        className="img-fluid rounded"
                        alt="facebook"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </Link>
                  </li>
                  <li className="text-center pe-3">
                    <Link
                      to="https://www.instagram.com/joinqaflah"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        loading="lazy"
                        src={instagram}
                        className="img-fluid rounded"
                        alt="Instagram"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </Link>
                  </li>
                  <li className="text-center pe-0 md-pe-3">
                    <Link
                      to="https://www.linkedin.com/company/joinqaflah/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        loading="lazy"
                        src={linkedin}
                        className="img-fluid rounded"
                        alt="linkedin"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ComingSoon;
