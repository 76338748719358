import axios from "axios";
import { React, useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectAuthToken } from "../../../store/auth/auth";
import { setGlobalError } from "../../../store/error/error";
import { setGlobalNotification } from "../../../store/notification/notification";

const SubscribeToWaitList = ({ showTextButton, companyId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const authToken = useSelector(selectAuthToken);
  const handleClose = () => toggleDialog(false);
  const [loading, setLoading] = useState(false);
  const handleShow = () => toggleDialog(true);
  const subscriberForm = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    subscriberForm.reset();
  }, []);

  const onSubmit = async (payload) => {
    if (!loading) {
      try {
        setLoading(true);
        await axios.post(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/subscribe/waitlist`,
          payload,
          {
            headers: {
              Authorization: `${process.env.REACT_APP_AUTH_NAME} ${authToken}`,
            },
          }
        );
        handleClose(true);
        setLoading(false);
        dispatch(
          setGlobalNotification(
            "You have successfully subscribed to the waiting list !"
          )
        );
      } catch (err) {
        setLoading(false);
        handleClose(true);
        const errorMessage = err?.response?.data?.message;
        if (errorMessage) {
          if (errorMessage == "email must be unique") {
            dispatch(setGlobalError("Error ! Your email is already subscribed to the waitlist."));
          } else {
            dispatch(setGlobalError(errorMessage));
          }
        } else {
          dispatch(setGlobalError("Error ! Something went wrong."));
        }
      }
    }
  };

  return (
    <>
      {showTextButton ? (
        <Button
          variant="primary"
          size="md"
          className="btn d-inline-flex btn-primary me-2 rounded-4"
          onClick={handleShow}
        >
          Subscribe to waitlist
        </Button>
      ) : (
        <Link variant="outline-danger" to="#" onClick={handleShow}>
          Subscribe to waitlist
        </Link>
      )}
      <Modal
        size="lg"
        show={showDialog}
        onHide={handleClose}
        style={{ display: "block" }}
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            Join the Waitlist for Updates
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Col sm="12">
              <Row>
                <Form.Group className="col-md-4 form-group">
                  <Form.Label>
                    Name<span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...subscriberForm.register("name", {
                      required: true,
                    })}
                    placeholder="Enter Your Name"
                  />
                  {subscriberForm.formState?.errors?.name && (
                    <span className="text-danger">This field is required</span>
                  )}
                </Form.Group>
                <Form.Group className="col-md-4 form-group">
                  <Form.Label>
                    Email<span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    {...subscriberForm.register("email", {
                      required: true,
                    })}
                    placeholder="Enter Your Email"
                  />
                  {subscriberForm.formState?.errors?.email && (
                    <span className="text-danger">This field is required</span>
                  )}
                </Form.Group>
                <Form.Group className="col-md-4 form-group">
                  <Form.Label className="form-label" htmlFor="subscriber-type">
                    Are you a ?<span className="text-danger"> *</span>
                  </Form.Label>
                  <select
                    className="form-select"
                    id="subscriber-type"
                    {...subscriberForm.register("subscriberType", {
                      required: true,
                    })}
                    placeholder="Identify as"
                  >
                    <option defaultValue value="2">
                      Startup
                    </option>
                    <option value="3">Investor</option>
                    <option value="4">ESO</option>
                    <option value="5">Corporate</option>
                    <option value="6">Startup Enthusiast</option>
                  </select>
                  {subscriberForm.formState?.errors?.subscriberType && (
                    <span className="text-danger">This field is required</span>
                  )}
                </Form.Group>
              </Row>
            </Col>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={subscriberForm.handleSubmit(onSubmit)}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Keep me updated"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscribeToWaitList;
