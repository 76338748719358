import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import { Link, Router, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import * as SettingSelector from "../../../store/setting/selectors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { setGlobalError, clearGlobalError } from "../../../store/error/error";
import qaflaLogo from "../../../assets/images/icon/qafla-transparent.png";
import { selectAuthToken, setAuth } from "../../../store/auth/auth";
import { jwtDecode } from "jwt-decode";
import { setGlobalNotification } from "../../../store/notification/notification";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {
  const appName = useSelector(SettingSelector.app_name);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const [showOTPScreen, toggleOTPScreen] = useState(false);
  const otpVerificationForm = useForm();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    const userStoredInformation = JSON.parse(
      localStorage.getItem("authenticatedUser")
    );
    try {
      if (userStoredInformation.token || authToken) {
        const decoded = jwtDecode(userStoredInformation.token || authToken);
        if (decoded?.userType == "2") {
          navigate("/dashboard/admin/control");
        } else {
          navigate("/dashboard");
        }
      }
    } 
    catch (x) {
      console.log("Error : ", x);
    }
  }, [authToken]);

  const onSubmit = async (data) => {
    try {
      dispatch(clearGlobalError());
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/auth/login`,
        {
          email: data.email,
          password: data.password,
        }
      );
      localStorage.setItem("authenticatedUser", JSON.stringify(response.data));
      dispatch(
        setAuth({
          userInfo: response.data.data,
          authToken: response.data.token,
        })
      );
      dispatch(setGlobalNotification("Login Successfull !"));
      // Routing User Based On Role
      setLoading(false);
      const decoded = jwtDecode(response.data.token);
      if (decoded?.userType == "2") {
        navigate("/dashboard/admin/control");
      } else {
        navigate("/dashboard");
      }
      //
    } catch (error) {
      setLoading(false);
      dispatch(setGlobalError("Error ! " + error.response?.data.message));
      if (error?.response?.status == 414) {
        toggleOTPScreen(true);
      }
    }
  };

  const verifyOTP = async (data) => {
    try {
      setLoading(true);
      dispatch(clearGlobalError());
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/auth/verify-otp`,
        {
          email: getValues("email"),
          otp: data.otp,
        }
      );
      dispatch(setGlobalNotification("Email verified successfully !"));
      toggleOTPScreen(false);
    } catch (error) {
      setLoading(false);
      dispatch(setGlobalError("Error ! " + error.response?.data.message));
    }
  };

  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <h2 className="mb-3 text-white fw-semibold">
                        Discover companies with search and AI-powered
                        recommendations
                      </h2>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h2 className="mb-3 text-white fw-semibold">
                        Take action at the right time with personalized workflow
                        tools
                      </h2>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h2 className="mb-3 text-white fw-semibold">
                        Stay informed with intelligent insights and real-time
                        alerts
                      </h2>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <Link
                  to="/"
                  className="d-inline-flex align-items-center justify-content-center gap-2"
                >
                  <img src={qaflaLogo} style={{ width: "50px" }} />
                  <h2 className="logo-title" data-setting="app_name">
                    Qaflah
                  </h2>
                </Link>
                <p className="mt-3 font-size-16">
                  Leverage AI-powered company intelligence to <br /> make better
                  decisions about your pipeline.
                </p>
                {!showOTPScreen ? (
                  <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="form-group text-start">
                      <h6 className="form-label fw-bold">Email Address</h6>
                      <Form.Control
                        type="email"
                        className="form-control mb-0"
                        placeholder="Your Email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="text-danger">{errors.email.message}</p>
                      )}
                    </Form.Group>
                    <Form.Group className="form-group text-start">
                      <h6 className="form-label fw-bold">Your Password</h6>
                      <Form.Control
                        type="password"
                        className="form-control mb-0"
                        placeholder="Password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                      />
                      {errors.password && (
                        <p className="text-danger">{errors.password.message}</p>
                      )}
                    </Form.Group>
                    <div className="d-flex align-items-center justify-content-between">
                      <Form.Check className="form-check d-inline-block m-0">
                        <Form.Check.Input
                          type="checkbox"
                          className="form-check-input"
                        />
                        <h6 className="form-check-label fw-bold">
                          Remember Me
                        </h6>
                      </Form.Check>
                      <Link to="/auth/recoverpw" className="font-italic">
                        Forgot Password?
                      </Link>
                    </div>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Sign in"
                      )}
                    </Button>
                    <h6 className="mt-5">
                      Don't Have An Account ?{" "}
                      <Link to="/auth/sign-up">Sign Up</Link>
                    </h6>
                  </Form>
                ) : (
                  <Form
                    className="mt-5"
                    onSubmit={otpVerificationForm.handleSubmit(verifyOTP)}
                  >
                    <Form.Group className="form-group text-start">
                      <h6 className="form-label fw-bold">
                        An OTP Code has been sent on your email
                      </h6>
                      <Form.Control
                        type="text"
                        className="form-control mb-0"
                        placeholder="please Enter Your OTP Here !"
                        {...otpVerificationForm.register("otp", {
                          required: "OTP is required",
                        })}
                      />
                      {otpVerificationForm?.formState.errors.otp && (
                        <p className="text-danger">
                          {otpVerificationForm?.formState.errors.otp.message}
                        </p>
                      )}
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Verify OTP"
                      )}
                    </Button>
                    <h6 className="mt-5">
                      Don't Have An Account ? <Link to="/auth/sign-up">Sign Up</Link>
                    </h6>
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignIn;
