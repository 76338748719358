import React from "react";
import Header from "../../components/partials/dashboard/HeaderStyle/header";
import RightSidebar from "../../components/partials/dashboard/SidebarStyle/rightsidebar";
import Sidebar from "../../components/partials/dashboard/SidebarStyle/sidebar";
import Footer from "../../components/partials/dashboard/FooterStyle/footer";
import SettingOffCanvas from "../../components/setting/SettingOffCanvas";
import { Outlet } from "react-router-dom";

const Default = () => {
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="position-relative">
          <Header />
          <Outlet />
        </div>
      </div>
      {/* <RightSidebar /> */}
      {/* <Footer /> */}
      <SettingOffCanvas />
    </>
  );
};

export default Default;
