import React, { useState } from "react";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { useForm } from "react-hook-form";
import axios from "axios";
import qaflaLogo from "../../../assets/images/icon/qafla-transparent.png";
import * as SettingSelector from "../../../store/setting/selectors";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setGlobalError, clearGlobalError } from "../../../store/error/error";
import { setGlobalNotification } from "../../../store/notification/notification";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignUp = () => {
  const appName = useSelector(SettingSelector.app_name);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      dispatch(clearGlobalError());
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/auth/signup`,
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          confirmPassword: data.password,
          dateOfBirth: data.dateOfBirth,
          gender: data.gender,
          userType: "0",
        }
      );
      dispatch(setGlobalNotification("Signup Process Completed !"));
      navigate("/");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setGlobalError("Error ! " + error.response?.data.message));
    }
  };

  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <h2 className="mb-3 text-white fw-semibold">
                        Discover companies with search and AI-powered
                        recommendations
                      </h2>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h2 className="mb-3 text-white fw-semibold">
                        Take action at the right time with personalized workflow
                        tools
                      </h2>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h2 className="mb-3 text-white fw-semibold">
                        Stay informed with intelligent insights and real-time
                        alerts
                      </h2>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <Link
                  to="/"
                  className="d-inline-flex align-items-center justify-content-center gap-2"
                >
                  <img src={qaflaLogo} style={{ width: "50px" }} />
                  <h2 className="logo-title" data-setting="app_name">
                    Qaflah
                  </h2>
                </Link>
                <p className="mt-3 font-size-16">
                  Leverage AI-powered company intelligence to <br /> make better
                  decisions about your pipeline.
                </p>
                <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className="form-group text-start">
                        <h6 className="form-label fw-bold">First Name</h6>
                        <Form.Control
                          type="text"
                          className="form-control mb-0"
                          placeholder="First Name"
                          {...register("firstName", {
                            required: "First name is required",
                          })}
                        />
                        {errors.firstName && (
                          <p className="text-danger">
                            {errors.firstName.message}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="form-group text-start">
                        <h6 className="form-label fw-bold">Last Name</h6>
                        <Form.Control
                          type="text"
                          className="form-control mb-0"
                          placeholder="Last Name"
                          {...register("lastName", {
                            required: "Last name is required",
                          })}
                        />
                        {errors.lastName && (
                          <p className="text-danger">
                            {errors.lastName.message}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className="form-group">
                        <h6 className="form-label fw-bold text-start">
                          Gender
                        </h6>
                        <select
                          className="form-select"
                          id="gender"
                          {...register("gender", {
                            required: "Gender is required",
                          })}
                          placeholder="Select Gender"
                        >
                          <option defaultValue value="Male">
                            Male
                          </option>
                          <option value="Female">Female</option>
                          <option value="Others">Other</option>
                        </select>
                        {errors.gender && (
                          <span className="text-danger">
                            {errors.gender.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="form-group text-start">
                        <h6 className="form-label fw-bold">Date of birth</h6>
                        <Form.Control
                          type="date"
                          {...register("dateOfBirth", {
                            required: "Date of birth is required",
                          })}
                        />
                        {errors.dateOfBirth && (
                          <p className="text-danger">
                            {errors.dateOfBirth.message}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Email Address</h6>
                    <Form.Control
                      type="email"
                      className="form-control mb-0"
                      placeholder="marvin@example.com"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Your Password</h6>
                    <Form.Control
                      type="password"
                      className="form-control mb-0"
                      placeholder="Password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 6,
                          message:
                            "Password must be at least 6 characters long",
                        },
                      })}
                    />
                    {errors.password && (
                      <p className="text-danger">{errors.password.message}</p>
                    )}
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Check className="form-check d-inline-block m-0">
                      <Form.Check.Input
                        type="checkbox"
                        className="form-check-input"
                        {...register("termsAccepted", {
                          required: "You must accept the terms and conditions",
                        })}
                      />
                      <h6 className="form-check-label fw-500 font-size-14">
                        I accept{" "}
                        <Link
                          className="fw-light ms-1"
                          to="/dashboard/extrapages/terms-of-service"
                        >
                          Terms and Conditions
                        </Link>
                      </h6>
                    </Form.Check>
                  </div>
                  {errors.termsAccepted && (
                    <p className="text-danger">
                      {errors.termsAccepted.message}
                    </p>
                  )}
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                  <h6 className="mt-5">
                    Already Have An Account ? <Link to="/auth/sign-in">Login</Link>
                  </h6>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignUp;
