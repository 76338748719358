import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  Nav,
  Tab,
  OverlayTrigger,
  Tooltip,
  Collapse,
  Button,
  Accordion,
  Image,
} from "react-bootstrap";
import Card from "../../../components/Card";
import { Link, useParams } from "react-router-dom";
import ReactFsLightbox from "fslightbox-react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthToken, selectUserInfo } from "../../../store/auth/auth";
import { formatNumberAsCurrency, timeAgo } from "../../../utilities/helpers";
import CreateNewsDialog from "../modals/create-news";
import CreateFAQDialog from "../modals/create-faq";
import CreateEventDialog from "../modals/create-event";
import { setGlobalNotification } from "../../../store/notification/notification";
import { jwtDecode } from "jwt-decode";

const CompanyProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [hasPremiumAccess, setPremiumAccess] = useState(false);
  const authToken = useSelector(selectAuthToken);
  const userObject = useSelector(selectUserInfo);
  const [details, setDetails] = useState(null);
  const [aboutCompanyData, setAboutCompanyData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [linkData, setLinkData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const profileImageURL =
    "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352010-stock-illustration-default-placeholder-man-and-woman.jpg";
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  useEffect(() => {
    document.body.classList.add("profile-page");
    return () => {
      document.body.classList.remove("profile-page");
    };
  });

  useEffect(() => {
    if (id && authToken) {
      if (userObject?.membershipType != 0) {
        console.log("User Object : ", userObject);
        setPremiumAccess(true);
      }
      if (!details) getCompanyDetail();
    }
  }, [id, authToken]);

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  const getCompanyDetail = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      console.log("Company Detail Response : ", response.data.data);

      let linkData = [];
      let aboutData = [
        {
          title: "Company Type : ",
          data: response.data.data?.company.companyType,
        },
        {
          title: "Company Size : ",
          data: response.data.data?.company.numberOfEmployee,
        },
        {
          title: "Last Funding Type : ",
          data: response.data.data?.funding[
            response.data.data?.funding.length - 1
          ]?.fundingType,
        },
      ];
      if (response.data.data?.company.twitterUrl) {
        linkData.push({
          title: "Twitter : ",
          data: response.data?.data.company.twitterUrl,
        });
      }
      if (response.data.data?.company.linkedInUrl) {
        linkData.push({
          title: "LinkedIn : ",
          data: response.data?.data.company.linkedInUrl,
        });
      }
      if (response.data.data?.company.facebookUrl) {
        linkData.push({
          title: "Facebook:",
          data: response.data?.data.company.facebookUrl,
        });
      }
      setLinkData(linkData);
      setDetails(response.data.data);
      setAboutCompanyData(aboutData);
      fetchNews(response.data.data?.company?.id);
      fetchEvents(response.data.data?.company?.id);
      getCompanyContacts(response.data.data?.company?.id);
      getCompanyEmployee(response.data.data?.company?.id);
    } catch (x) {
      console.error(x);
    }
  };

  const getCompanyContacts = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company/contact/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Contact Data : ", response.data);
      setContacts(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const getCompanyEmployee = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company/employee/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Employee Data : ", response.data);
      setEmployee(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchNews = async (companyId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/news/company/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setNewsData(response.data.data);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const fetchEvents = async (companyId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/event/company/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setEventData(response.data.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const followUser = async (userId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/user/follow`,
        {
          followerId: userObject?.id,
          followeeId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      dispatch(setGlobalNotification("Followed user profile successfully !"));
    } catch (error) {
      console.error("Error ! Cannot follow user at the moment");
    }
  };

  return (
    <>
      <div className="custom-container">
        <div className="custom-container" style={{ paddingTop: "250px" }}>
          <Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Col sm={12}>
                <Card className="profile-box">
                  <Card.Body>
                    <Row className="align-items-center item-header-content">
                      <Col lg={4} className="profile-left">
                        <ul className="user-meta list-inline p-0 d-flex align-items-center justify-content-center">
                          <li>
                            <h5>$6.4B</h5>Total Funding
                          </li>
                          <li>
                            <h5>-</h5>Exits
                          </li>
                          <li>
                            <h5>{contacts.length}</h5>Contacts
                          </li>
                        </ul>
                      </Col>
                      <Col lg={4} className="text-center profile-center">
                        <div className="header-avatar position-relative d-inline-block">
                          <span className="change-profile-image bg-primary rounded-pill">
                            <span className="material-symbols-outlined text-white font-size-16">
                              photo_camera
                            </span>
                          </span>
                          <img
                            src={details?.company?.companyLogoUrl}
                            alt="user"
                            className="avatar-150 border border-4 border-white rounded-3 object-fit-cover"
                          />
                          <span className="badge bg-success fw-500 letter-spacing-1 chat-status">
                            Organization
                          </span>
                        </div>
                        <h5 className="d-flex align-items-center justify-content-center gap-1 mb-2">
                          {details?.company?.name}{" "}
                          <span className="badge  bg-primary rounded-pill material-symbols-outlined font-size-14 p-0 custom-done">
                            done
                          </span>
                        </h5>
                        <ul className="d-flex align-items-center justify-content-center gap-3 list-inline p-0 m-0">
                          <li className="d-flex align-items-center gap-1">
                            <h6 className="material-symbols-outlined font-size-14">
                              location_on
                            </h6>
                            <span className="font-size-14 text-uppercase fw-500">
                              San Francisco
                            </span>
                          </li>
                          <li className="d-flex align-items-center gap-1">
                            <h6 className="material-symbols-outlined font-size-14">
                              globe_asia
                            </h6>
                            <Link
                              to={details?.company?.websiteUrl}
                              className="font-size-14 fw-500 text-body"
                            >
                              {details?.company?.websiteUrl}
                            </Link>
                          </li>
                        </ul>
                      </Col>
                      <Col lg={4} className="profile-right">
                        <ul className="user-meta list-inline p-0 d-flex align-items-center justify-content-center">
                          <li>
                            <h5>{details?.ipo?.stockSymbol || "-"}</h5>Stock
                            Symbol
                          </li>
                          <li>
                            <h5>-</h5>Lead Investments
                          </li>
                          <li>
                            <h5>
                              {details?.ipo?.valuation
                                ? "$" +
                                  formatNumberAsCurrency(
                                    Number(details?.ipo?.valuation)
                                  )
                                : "-"}
                            </h5>
                            Valuation
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body className="p-0">
                    <div className="user-tabing item-list-tabs">
                      <Nav
                        as="ul"
                        variant="pills"
                        className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 rounded"
                      >
                        <Nav.Item as="li" className=" col-12 col-sm-3">
                          <Nav.Link
                            href="#company-overview"
                            eventKey="first"
                            role="button"
                            className=" d-flex flex-md-column align-items-center flex-row justify-content-center gap-2"
                          >
                            <span className="icon rounded-3">
                              <span className="material-symbols-outlined">
                                description
                              </span>
                            </span>
                            <p className="mb-0 mt-0 mt-md-3">Summary</p>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="col-12 col-sm-3">
                          <Nav.Link
                            href="#company-financial"
                            eventKey="second"
                            role="button"
                            className="d-flex flex-md-column align-items-center flex-row justify-content-center gap-2"
                          >
                            <span className="icon rounded-3">
                              <span className="material-symbols-outlined">
                                <span className="material-symbols-outlined">
                                  attach_money
                                </span>
                              </span>
                            </span>{" "}
                            <p className="mb-0 mt-0 mt-md-3">Financials</p>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className=" col-12 col-sm-3">
                          <Nav.Link
                            href="#company-people"
                            eventKey="third"
                            role="button"
                            className="d-flex flex-md-column align-items-center flex-row justify-content-center gap-2"
                          >
                            <span className="icon rounded-3">
                              <span className="material-symbols-outlined">
                                group
                              </span>
                            </span>
                            <p className="mb-0 mt-0 mt-md-3">People</p>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="col-12 col-sm-3">
                          <Nav.Link
                            href="#company-news"
                            eventKey="fourth"
                            role="button"
                            className="d-flex flex-md-column align-items-center flex-row justify-content-center gap-2"
                          >
                            <span className="icon rounded-3">
                              <span className="material-symbols-outlined">
                                breaking_news
                              </span>
                            </span>
                            <p className="mb-0 mt-0 mt-md-3">Signal & News</p>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {details && (
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Card.Body className=" p-0">
                        <Row>
                          <Col sm={12}>
                            <h3 className="mb-3">Overview</h3>
                            <p className="mt-3">
                              {details?.company?.description}
                            </p>
                          </Col>
                          <Col md={4}>
                            <Card>
                              <Card.Body>
                                <h4>About</h4>
                                <hr />
                                <p>Tagline : {details?.company?.tagLine}</p>
                                <div className="table-responsive">
                                  <table className="table profile-table">
                                    <tbody>
                                      {aboutCompanyData.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <h6>{item.title}</h6>
                                            </td>
                                            <td>
                                              <p className="mb-0">
                                                {item.data}
                                              </p>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                                <h4 className="mt-2">
                                  Websites and Social Links
                                </h4>
                                <hr />
                                <div className="table-responsive">
                                  <table className="table profile-table">
                                    <tbody>
                                      {linkData.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <h6>{item.title}</h6>
                                            </td>
                                            <td>
                                              <p className="mb-0">
                                                {item.data}
                                              </p>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={8}>
                            <Card>
                              <Card.Body>
                                <h4>Detail</h4>
                                <hr />
                                <Row>
                                  <Col sm={6}>
                                    <div className="mb-2">
                                      <h6>Founded Date</h6>
                                      <p>
                                        {new Date(
                                          details?.company?.foundedDate
                                        ).toDateString()}
                                      </p>
                                    </div>
                                    <div className="mb-2">
                                      <h6>Operating Status</h6>
                                      <p>
                                        {details?.company?.closedData
                                          ? "In-Active"
                                          : "Active"}
                                      </p>
                                    </div>
                                    <div className="mb-2">
                                      <h6>Also Known As</h6>
                                      <p>
                                        {details?.company?.alsoKnownAs
                                          ? details?.company?.alsoKnownAs
                                          : "-"}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col sm={6}>
                                    <div className="mb-2">
                                      <h6>Founders </h6>
                                      <p>
                                        {details?.company.founders
                                          ? details?.company.founders
                                          : "-"}
                                      </p>
                                    </div>
                                    <div className="mb-2">
                                      <h6>Last Funding Type</h6>
                                      <p>
                                        {details?.funding[
                                          details?.funding.length - 1
                                        ]?.fundingType
                                          ? details?.funding[
                                              details?.funding.length - 1
                                            ]?.fundingType
                                          : "-"}
                                      </p>
                                    </div>
                                    <div className="mb-2">
                                      <h6>Legal Name </h6>
                                      <p>
                                        {details?.company?.legalName
                                          ? details?.company?.legalName
                                          : "-"}
                                      </p>
                                    </div>
                                    {/* <div className="mb-2">
                                    <h6>Hub Tags </h6>
                                    <p>Exited Unicorn</p>
                                  </div> */}
                                  </Col>
                                  <hr />
                                  <Col sm={6}>
                                    <div className="mb-2">
                                      <h6>Stock Symbol</h6>
                                      <p>LSE:0A8C</p>
                                    </div>
                                  </Col>
                                  <Col sm={6}>
                                    <div className="mb-2">
                                      <h6>Company Type</h6>
                                      <p>
                                        {details?.company?.companyType
                                          ? details?.company?.companyType
                                          : "-"}
                                      </p>
                                    </div>
                                  </Col>
                                  <hr />
                                  <Col sm={6}>
                                    <div className="mb-2">
                                      <h6>Exits</h6>
                                      <p>2</p>
                                    </div>
                                  </Col>
                                  <hr />
                                  <Col sm={6}>
                                    <div className="mb-2">
                                      <h6>Contact Email</h6>
                                      <p>
                                        {details?.company?.contactEmail
                                          ? details?.company?.contactEmail
                                          : "-"}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col sm={6}>
                                    <div className="mb-2">
                                      <h6>Phone Number</h6>
                                      <p>
                                        {details?.company?.phoneNumber
                                          ? details?.company?.phoneNumber
                                          : "-"}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col
                            sm={
                              details.faq?.length > 0 &&
                              details.news?.length == 0
                                ? 12
                                : 6
                            }
                          >
                            {details?.faq && details.faq.length > 0 && (
                              <Card>
                                <Card.Body>
                                  <h4>Frequently Asked Questions</h4>
                                  <hr />
                                  <Accordion id="accordionExample">
                                    {details?.faq.map((questionObj, index) => (
                                      <Accordion.Item
                                        className="mb-3"
                                        eventKey={index}
                                      >
                                        <Accordion.Header id="heading1">
                                          {questionObj.question}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <p>{questionObj.answer}</p>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    ))}
                                  </Accordion>
                                </Card.Body>
                              </Card>
                            )}
                          </Col>
                          <Col
                            sm={
                              details.faq?.length == 0 &&
                              details.news?.length > 0
                                ? 12
                                : 6
                            }
                          >
                            {details?.news && details?.news.length > 0 && (
                              <Card>
                                <Card.Body>
                                  <h3>Recent News & Articles</h3>
                                  <p className="mt-2 mb-2 text-danger">
                                    Number of Articles - {details?.news?.length}
                                  </p>
                                  <ul className="notification-list m-0 p-0">
                                    {details?.news &&
                                      details?.news.lenth > 0 &&
                                      details?.news.map((newsObj) => (
                                        <>
                                          <li className="d-flex align-items-center justify-content-between mt-4">
                                            <div className="w-100">
                                              <div className="d-flex justify-content-between">
                                                <div className="ms-3">
                                                  <h6>{newsObj.title}</h6>
                                                  <p className="mb-0 text-primary">
                                                    {timeAgo(
                                                      newsObj?.createdAt
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                  <Link
                                                    to={newsObj.link}
                                                    className="btn btn-icon btn-success-subtle btn-sm me-3"
                                                  >
                                                    <span className="btn-inner">
                                                      <i className="material-symbols-outlined font-size-16">
                                                        visibility
                                                      </i>
                                                    </span>
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <hr />
                                        </>
                                      ))}
                                    {details.news.map((newsObject) => (
                                      <>
                                        <li className="d-flex align-items-center justify-content-between mt-4">
                                          <div className="w-100">
                                            <div className="d-flex justify-content-between">
                                              <div className="ms-3">
                                                <h6>{newsObject.title}</h6>
                                                <p className="mb-0 text-primary">
                                                  {timeAgo(
                                                    newsObject.createdAt
                                                  )}
                                                </p>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <Link
                                                  to={newsObject?.link}
                                                  className="btn btn-icon btn-success-subtle btn-sm me-3"
                                                >
                                                  <span className="btn-inner">
                                                    <i className="material-symbols-outlined font-size-16">
                                                      visibility
                                                    </i>
                                                  </span>
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <hr />
                                      </>
                                    ))}
                                  </ul>
                                </Card.Body>
                              </Card>
                            )}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div style={{ filter: "blur(0.5em)" }}>
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="about1"
                        >
                          <h4 className="mb-3">Funding</h4>
                          <p>
                            Airbnb has raised a total of $6.4B in funding over
                            30 rounds. Their latest funding was raised on Jun 3,
                            2020 from a Secondary Market round.
                          </p>
                          <p>Airbnb is registered under the ticker LSE:0A8C.</p>
                          <p>
                            Airbnb is funded by 81 investors. Jean-Sébastien
                            Wallez and Employee Stock Option Fund are the most
                            recent investors.
                          </p>
                          <p>
                            Airbnb has a post-money valuation in the range of
                            $10B+ as of Apr 6, 2020, according to PrivCo. Sign
                            up for a free trial to view exact valuation and
                            search companies with similar valuations.
                          </p>
                          <p>
                            Airbnb has made 20 investments. Their most recent
                            investment was on Oct 30, 2023, when Samara raised
                            $41M.
                          </p>
                          <p>
                            Airbnb has invested in The Wing on Oct 30, 2023.
                            This investment - Series C - The Wing - was valued
                            at $41M.
                          </p>
                          <p>
                            Airbnb has had 2 exits. Airbnb's most notable exits
                            include Resy, American Express Global Dining Network
                            and The Wing.
                          </p>
                          <p>
                            Airbnb has acquired 26 organizations. Their most
                            recent acquisition was GamePlanner.AI on Nov 14,
                            2023. They acquired GamePlanner.AI for $200M.
                          </p>
                          <Row as="ul" className="list-unstyled mb-0 mt-5">
                            <Col md="6" lg="2" as="li">
                              <Card>
                                <Card.Body>
                                  <div className="points">
                                    <span>Stock Symbol</span>
                                    <div className="d-flex align-items-center">
                                      <h3>LSE:0A8C</h3>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md="6" lg="2" as="li">
                              <Card>
                                <Card.Body>
                                  <div className="points">
                                    <span>Aquistions</span>
                                    <div className="d-flex align-items-center">
                                      <h3>26</h3>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md="6" lg="2" as="li">
                              <Card>
                                <Card.Body>
                                  <div className="points">
                                    <span>Investments</span>
                                    <div className="d-flex align-items-center">
                                      <h3>20</h3>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md="6" lg="2" as="li">
                              <Card>
                                <Card.Body>
                                  <div className="points">
                                    <span>Exits</span>
                                    <div className="d-flex align-items-center">
                                      <h3>2</h3>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md="6" lg="2" as="li">
                              <Card>
                                <Card.Body>
                                  <div className="points">
                                    <span>Funding Amount</span>
                                    <div className="d-flex align-items-center">
                                      <h3>$6.9B</h3>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col md="6" lg="2" as="li">
                              <Card>
                                <Card.Body>
                                  <div className="points">
                                    <span>Contacts</span>
                                    <div className="d-flex align-items-center">
                                      <h3>9,822</h3>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Card>
                              <Card.Body>
                                <h4>Funding Rounds</h4>
                                <p className="mt-2 mb-2 ms-3">
                                  Airbnb has raised a total of $6.4B in funding
                                  over 30 rounds. Their latest funding was
                                  raised on Jun 3, 2020 from a Secondary Market
                                  round.
                                </p>
                                <Accordion
                                  id="accordionExample"
                                  className="mb-2"
                                >
                                  <Accordion.Item
                                    className="mb-3 border"
                                    eventKey="0"
                                  >
                                    <Accordion.Header id="heading1">
                                      Which funding types raised the most money?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <p>
                                        Many desktop publishing packages and web
                                        page editors now use Lorem Ipsum as
                                        their default model text, and a search
                                        for 'lorem ipsum' will uncover many web
                                        sites still in their infancy.
                                      </p>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item
                                    className="mb-3 border"
                                    eventKey="1"
                                  >
                                    <Accordion.Header id="heading1">
                                      How much funding has this organization
                                      raised over time?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <p>
                                        Many desktop publishing packages and web
                                        page editors now use Lorem Ipsum as
                                        their default model text, and a search
                                        for 'lorem ipsum' will uncover many web
                                        sites still in their infancy.
                                      </p>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                                <Row>
                                  <div id="table" className="table-editable">
                                    <table className="table table-bordered table-responsive-md table-striped text-center">
                                      <thead>
                                        <tr>
                                          <th>Announced Date</th>
                                          <th>Transaction Name</th>
                                          <th>Number of Investors</th>
                                          <th>Money Raised</th>
                                          <th>Lead Investors</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <b>Jun 3, 2020</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Secondary Market - Airbnb
                                          </td>
                                          <td>3</td>
                                          <td>–</td>
                                          <td>–</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Apr 14, 2020</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Debt Financing - Airbnb
                                          </td>
                                          <td>7</td>
                                          <td>$1B</td>
                                          <td>–</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Apr 6, 2020</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Private Equity Round - Airbnb
                                          </td>
                                          <td>2</td>
                                          <td>$1B</td>
                                          <td>Silver Lake, Sixth Street</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Dec 30, 2019</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Secondary Market - Airbnb
                                          </td>
                                          <td>1</td>
                                          <td>$25M</td>
                                          <td>–</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Aug 1, 2019</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Secondary Market - Airbnb
                                          </td>
                                          <td>1</td>
                                          <td>–</td>
                                          <td>–</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Jun 1, 2019</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Series G - Airbnb
                                          </td>
                                          <td>1</td>
                                          <td>–</td>
                                          <td>–</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Jan 1, 2019</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Secondary Market - Airbnb
                                          </td>
                                          <td>1</td>
                                          <td>–</td>
                                          <td>–</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Nov 12, 2018</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Series F - Airbnb
                                          </td>
                                          <td>1</td>
                                          <td>–</td>
                                          <td>–</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Sep 5, 2018</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Secondary Market - Airbnb
                                          </td>
                                          <td>2</td>
                                          <td>–</td>
                                          <td>–</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b>Jan 29, 2018</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            Venture Round - Airbnb
                                          </td>
                                          <td>1</td>
                                          <td>–</td>
                                          <td>–</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Row>
                          <Row>
                            <Card>
                              <Card.Body>
                                <h4>Investments</h4>
                                <p className="mt-2 mb-2 ms-3">
                                  Airbnb has made 20 investments. Their most
                                  recent investment was on Oct 30, 2023, when
                                  Samara raised $41M.
                                </p>
                                <Accordion
                                  id="accordionExample"
                                  className="mb-2"
                                >
                                  <Accordion.Item
                                    className="mb-3 border"
                                    eventKey="0"
                                  >
                                    <Accordion.Header id="heading1">
                                      How many investments has this organization
                                      made over time?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <p>
                                        Many desktop publishing packages and web
                                        page editors now use Lorem Ipsum as
                                        their default model text, and a search
                                        for 'lorem ipsum' will uncover many web
                                        sites still in their infancy.
                                      </p>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item
                                    className="mb-3 border"
                                    eventKey="1"
                                  >
                                    <Accordion.Header id="heading1">
                                      Which industries has this organization
                                      most actively invested in?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <p>
                                        Many desktop publishing packages and web
                                        page editors now use Lorem Ipsum as
                                        their default model text, and a search
                                        for 'lorem ipsum' will uncover many web
                                        sites still in their infancy.
                                      </p>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                                <Row>
                                  <div id="table" className="table-editable">
                                    <table className="table table-bordered table-responsive-md table-striped text-center">
                                      <thead>
                                        <tr>
                                          <th>Announced Date</th>
                                          <th>Organization Name</th>
                                          <th>Lead Investor</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <b>Oct 30, 2023</b>
                                          </td>
                                          <td className="d-flex align-items-center">
                                            Samara
                                          </td>
                                          <td>-</td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <b>Oct 30, 2023</b>
                                          </td>
                                          <td className="d-flex align-items-center">
                                            Samara
                                          </td>
                                          <td>-</td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <b>Oct 30, 2023</b>
                                          </td>
                                          <td className="d-flex align-items-center">
                                            Samara
                                          </td>
                                          <td>-</td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <b>Oct 30, 2023</b>
                                          </td>
                                          <td className="d-flex align-items-center">
                                            Samara
                                          </td>
                                          <td>-</td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <b>Oct 30, 2023</b>
                                          </td>
                                          <td className="d-flex align-items-center">
                                            Samara
                                          </td>
                                          <td>-</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <nav aria-label="Page navigation example">
                                      <ul className="pagination justify-content-center">
                                        <li className="page-item disabled">
                                          <Link
                                            className="page-link"
                                            to="#"
                                            tabIndex="-1"
                                          >
                                            Previous
                                          </Link>
                                        </li>
                                        <li className="page-item">
                                          <Link className="page-link" to="#">
                                            1
                                          </Link>
                                        </li>
                                        <li className="page-item">
                                          <Link className="page-link" to="#">
                                            2
                                          </Link>
                                        </li>
                                        <li className="page-item">
                                          <Link className="page-link" to="#">
                                            3
                                          </Link>
                                        </li>
                                        <li className="page-item">
                                          <Link className="page-link" to="#">
                                            Next
                                          </Link>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Row>
                          <Row>
                            <Card>
                              <Card.Body>
                                <h4>Diversity Investments</h4>
                                <p className="mt-2 mb-2 ms-3">
                                  Airbnb has invested in The Wing on Oct 30,
                                  2023. This investment - Series C - The Wing -
                                  was valued at $41M.
                                </p>
                                <Row>
                                  <div id="table" className="table-editable">
                                    <table className="table table-bordered table-responsive-md table-striped text-center">
                                      <thead>
                                        <tr>
                                          <th>Announced Date</th>
                                          <th>Organization Name</th>
                                          <th>Diversity Spotlight</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <b>Oct 30, 2023</b>
                                          </td>
                                          <td className="d-flex align-items-center justify-content-center">
                                            The Wing
                                          </td>
                                          <td>Women Founded, Women Led</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Row>
                          <Row>
                            <Card>
                              <Card.Body>
                                <h4>Acquisitions</h4>
                                <p className="mt-2 mb-2 ms-3">
                                  Airbnb has acquired 26 organizations. Their
                                  most recent acquisition was GamePlanner.AI on
                                  Nov 14, 2023. They acquired GamePlanner.AI for
                                  $200M. Which types of acquisition does this
                                  organization ma
                                </p>
                                <Accordion
                                  id="accordionExample"
                                  className="mb-2"
                                >
                                  <Accordion.Item
                                    className="mb-3 border"
                                    eventKey="0"
                                  >
                                    <Accordion.Header id="heading1">
                                      Which types of acquisition does this
                                      organization make most frequently?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <p>
                                        Many desktop publishing packages and web
                                        page editors now use Lorem Ipsum as
                                        their default model text, and a search
                                        for 'lorem ipsum' will uncover many web
                                        sites still in their infancy.
                                      </p>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                                <Row>
                                  <div id="table" className="table-editable">
                                    <table className="table table-bordered table-responsive-md table-striped text-center">
                                      <thead>
                                        <tr>
                                          <th>Acquiree Name</th>
                                          <th>Announced Date</th>
                                          <th>Price</th>
                                          <th>Transaction Name</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <b>GamePlanner.AI</b>
                                          </td>
                                          <td>Nov 16, 2017 </td>
                                          <td>$200M</td>
                                          <td>
                                            GamePlanner.AI acquired by Airbnb
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <b>Urbandoor</b>
                                          </td>
                                          <td>Nov 16, 2017 </td>
                                          <td>-</td>
                                          <td>Urbandoor acquired by Airbnb</td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <b>CultureForce</b>
                                          </td>
                                          <td>Nov 16, 2017 </td>
                                          <td>-</td>
                                          <td>
                                            CultureForce acquired by Airbnb
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <b>HotelTonight</b>
                                          </td>
                                          <td>Nov 16, 2017 </td>
                                          <td>-</td>
                                          <td>
                                            HotelTonight acquired by Airbnb
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <b>Gaest.com</b>
                                          </td>
                                          <td>Nov 16, 2017 </td>
                                          <td>-</td>
                                          <td>Gaest.com acquired by Airbnb</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <nav aria-label="Page navigation example">
                                      <ul className="pagination justify-content-center">
                                        <li className="page-item disabled">
                                          <Link
                                            className="page-link"
                                            to="#"
                                            tabIndex="-1"
                                          >
                                            Previous
                                          </Link>
                                        </li>
                                        <li className="page-item">
                                          <Link className="page-link" to="#">
                                            1
                                          </Link>
                                        </li>
                                        <li className="page-item">
                                          <Link className="page-link" to="#">
                                            2
                                          </Link>
                                        </li>
                                        <li className="page-item">
                                          <Link className="page-link" to="#">
                                            3
                                          </Link>
                                        </li>
                                        <li className="page-item">
                                          <Link className="page-link" to="#">
                                            Next
                                          </Link>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Row>
                        </Tab.Container>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all-friends"
                      >
                        <h3 className="mb-3">People</h3>
                        <p>
                          Airbnb has 199 current employee profiles, including
                          CEO & Co-Founder Brian Chesky.
                        </p>
                        <p>
                          Airbnb has 16 board members and advisors, including
                          Alfred Lin.
                        </p>
                        <Row as="ul" className="list-unstyled mb-0">
                          <Col md="6" lg="4" as="li">
                            <Card>
                              <Card.Body>
                                <div className="points">
                                  <span>Employee Profiles</span>
                                  <div className="d-flex align-items-center">
                                    <h3>{employee.length}</h3>
                                    {/* <small className="text-success ms-3">
                                    + 57
                                  </small> */}
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md="6" lg="4" as="li">
                            <Card>
                              <Card.Body>
                                <div className="points">
                                  <span>Number of Board Members</span>
                                  <div className="d-flex align-items-center">
                                    <h3>{employee.length}</h3>
                                    {/* <small className="text-danger ms-3">
                                    - 12,562
                                  </small> */}
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md="6" lg="4" as="li">
                            <Card>
                              <Card.Body>
                                <div className="points">
                                  <span>Contacts</span>
                                  <div className="d-flex align-items-center">
                                    <h3>{contacts.length}</h3>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Card>
                          <Card.Body className="p-4">
                            <h2>People</h2>
                            <Row>
                              <div id="table" className="table-editable">
                                <span className="table-add float-end mb-3 me-2"></span>
                                <table className="table table-bordered table-responsive-md table-striped text-left">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Contact</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {contacts.map((contactObj) => (
                                      <tr>
                                        <td>
                                          <b>
                                            {contactObj.firstName}{" "}
                                            {contactObj.lastName}
                                          </b>
                                          <br />
                                          {contactObj.currentRole
                                            ? contactObj.currentRole
                                            : "-"}
                                        </td>
                                        <td className="d-flex align-items-center">
                                          <ul className="list-inline p-0 m-0">
                                            <li className="mb-2 d-flex align-items-center">
                                              <span className="material-symbols-outlined font-size-16">
                                                call
                                              </span>
                                              <p className="mb-0 ms-2">
                                                1 Phone Number
                                              </p>
                                            </li>
                                            <li className="mb-2 d-flex align-items-center">
                                              <span className="material-symbols-outlined font-size-16">
                                                mail
                                              </span>
                                              <p className="mb-0 ms-2">
                                                1 Email Address
                                              </p>
                                            </li>
                                          </ul>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center justify-content-around pt-3">
                                            {contactObj.linkedInProfileUrl && (
                                              <Link
                                                to={
                                                  contactObj.linkedInProfileUrl
                                                }
                                                className="btn btn-icon btn-primary-subtle btn-sm me-3"
                                              >
                                                <span className="btn-inner">
                                                  <img
                                                    width="48"
                                                    height="48"
                                                    src="https://img.icons8.com/fluency/48/linkedin.png"
                                                    alt="linkedin"
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                    }}
                                                  />
                                                </span>
                                              </Link>
                                            )}
                                            {contactObj.twitterProfileUrl && (
                                              <Link
                                                to={
                                                  contactObj.twitterProfileUrl
                                                }
                                                className="btn btn-icon btn-primary-subtle btn-sm me-3"
                                              >
                                                <span className="btn-inner">
                                                  <img
                                                    width="24"
                                                    height="24"
                                                    src="https://img.icons8.com/color/48/twitterx--v1.png"
                                                    alt="twitterx--v1"
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                    }}
                                                  />
                                                </span>
                                              </Link>
                                            )}
                                            {contactObj.phoneNumber && (
                                              <Link
                                                to="#"
                                                title={contactObj.phoneNumber}
                                                className="btn btn-icon btn-primary-subtle btn-sm me-3"
                                              >
                                                <span className="btn-inner">
                                                  <i className="material-symbols-outlined md-18 font-size-16">
                                                    phone
                                                  </i>
                                                </span>
                                              </Link>
                                            )}
                                            {contactObj.email && (
                                              <Link
                                                to="#"
                                                title={contactObj.email}
                                                className="btn btn-icon btn-primary-subtle btn-sm me-3"
                                              >
                                                <span className="btn-inner">
                                                  <i className="material-symbols-outlined md-18 font-size-16">
                                                    mail
                                                  </i>
                                                </span>
                                              </Link>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </Row>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Body className="p-4">
                            <h2>Employee Profiles</h2>
                            <div className="card-body p-0">
                              <div className="row">
                                <p className="pt-4">
                                  Airbnb has{" "}
                                  {details?.company?.numberOfEmployee} current
                                  employee profiles.
                                </p>
                                {employee.map((emp) => (
                                  <div className="col-md-6 col-lg-6 mb-3">
                                    <div className="iq-friendlist-block">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                          <Link to="#">
                                            <img
                                              loading="lazy"
                                              src={
                                                emp.profilePictureUrl
                                                  ? emp.profilePictureUrl
                                                  : profileImageURL
                                              }
                                              alt="profile-img"
                                              className="img-fluid"
                                              style={{
                                                width: "70px",
                                                height: "70px",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </Link>
                                          <div className="friend-info ms-3">
                                            <h5>
                                              {emp.firstName} {emp.lastName}
                                            </h5>
                                            <p className="mb-0">
                                              {emp.currentRole
                                                ? emp.currentRole
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        {emp.id != userObject?.id && (
                                          <div className="card-header-toolbar d-flex align-items-center">
                                            <Dropdown
                                              onClick={() => followUser(emp.id)}
                                            >
                                              <Dropdown.Toggle
                                                as="span"
                                                className="btn btn-secondary me-2 d-flex align-items-center"
                                              >
                                                <i className="material-symbols-outlined me-2">
                                                  done
                                                </i>
                                                Follow
                                              </Dropdown.Toggle>
                                            </Dropdown>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Body className="p-4">
                            <h2>Board Member and Advisor Profiles</h2>
                            <div className="card-body p-0">
                              <div className="row">
                                <p className="pt-4">
                                  Airbnb has 16 board members and advisors,
                                  including Alfred Lin.
                                </p>
                                {employee.map((emp) => (
                                  <div className="col-md-6 col-lg-6 mb-3">
                                    <div className="iq-friendlist-block">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                          <Link to="#">
                                            <img
                                              loading="lazy"
                                              src={
                                                emp.profilePictureUrl
                                                  ? emp.profilePictureUrl
                                                  : profileImageURL
                                              }
                                              alt="profile-img"
                                              className="img-fluid"
                                              style={{
                                                width: "70px",
                                                height: "70px",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </Link>
                                          <div className="friend-info ms-3">
                                            <h5>
                                              {emp.firstName} {emp.lastName}
                                            </h5>
                                            <p className="mb-0">
                                              {emp.currentRole
                                                ? emp.currentRole
                                                : "-"}
                                            </p>
                                          </div>
                                        </div>
                                        {emp.id != userObject?.id && (
                                          <div className="card-header-toolbar d-flex align-items-center">
                                            <Dropdown
                                              onClick={() => followUser(emp.id)}
                                            >
                                              <Dropdown.Toggle
                                                as="span"
                                                className="btn btn-secondary me-2 d-flex align-items-center"
                                              >
                                                <i className="material-symbols-outlined me-2">
                                                  done
                                                </i>
                                                Follow
                                              </Dropdown.Toggle>
                                            </Dropdown>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Tab.Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <Row>
                        {hasPremiumAccess && (
                          <Col sm="12">
                            <div className="d-flex justify-content-end align-items-center mb-5">
                              <CreateEventDialog
                                showTextButton
                                companyId={details?.company?.id}
                              />
                              <CreateNewsDialog
                                showTextButton
                                companyId={details?.company?.id}
                              />
                              <CreateFAQDialog
                                showTextButton
                                companyId={details?.company?.id}
                              />
                            </div>
                          </Col>
                        )}
                        {eventData.map((eventObj) => (
                          <Col lg="12">
                            <Card className="card-block card-stretch card-height blog-list">
                              <Card.Body>
                                <Row className="align-items-center">
                                  <Col md="6">
                                    <div className="image-block">
                                      <Image
                                        src={eventObj?.eventBannerImage}
                                        className="img-fluid rounded w-100"
                                        style={{
                                          height: "250px",
                                          objectFit: "cover",
                                        }}
                                        alt="blog-img"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="blog-description rounded p-2">
                                      <div className="blog-meta d-flex align-items-center justify-content-between mb-2">
                                        <div className="date">
                                          <Link to="#" tabIndex="-1">
                                            {timeAgo(eventObj?.eventStartDate)}
                                          </Link>
                                        </div>
                                      </div>
                                      <h5 className="mb-2">
                                        {eventObj?.title}
                                      </h5>
                                      <p>{eventObj?.shortDescription}</p>{" "}
                                      <Link
                                        to={eventObj?.eventUrl}
                                        tabIndex="-1"
                                        className="d-flex align-items-center"
                                      >
                                        Read More{" "}
                                        <i className="material-symbols-outlined fs-6 icon-rtl">
                                          arrow_forward_ios
                                        </i>
                                      </Link>
                                      <div className="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between position-right-side">
                                        <div className="comment d-flex align-items-center">
                                          <i className="material-symbols-outlined me-2 md-18">
                                            visibility
                                          </i>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                        <Col>
                          <Card>
                            <Card.Body>
                              <h3>Recent News & Article</h3>
                              <p className="mt-2 mb-2 text-danger">
                                Number of New/Articles - {newsData.length}
                              </p>
                              <ul className="notification-list m-0 p-0">
                                {newsData.map((newsObj) => (
                                  <>
                                    <li className="d-flex align-items-center justify-content-between mt-4">
                                      <div className="w-100">
                                        <div className="d-flex justify-content-between">
                                          <div className="ms-3">
                                            <h6>{newsObj.title}</h6>
                                            <p className="mb-0 text-primary">
                                              {timeAgo(newsObj?.createdAt)}
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              to={newsObj?.link}
                                              className="btn btn-icon btn-success-subtle btn-sm me-3"
                                            >
                                              <span className="btn-inner">
                                                <i className="material-symbols-outlined font-size-16">
                                                  visibility
                                                </i>
                                              </span>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <hr />
                                  </>
                                ))}
                              </ul>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              )}
            </Tab.Container>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
