//scss
import "./assets/scss/socialv.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/customizer.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "choices.js/public/assets/styles/choices.min.css";
import "flatpickr/dist/flatpickr.css";
import { useDispatch } from "react-redux";
import { setSetting } from "./store/setting/actions";
import { useSelector } from "react-redux";
import "./assets/custom/scss/socialv-custom.scss";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import { clearAuth, setAuth } from "./store/auth/auth";
import { selectGlobalError, clearGlobalError } from "./store/error/error";
import { selectGlobalNotification, clearGlobalNotification } from "./store/notification/notification";

function App({ children }) {
  const dispatch = useDispatch();
  const globalError = useSelector(selectGlobalError);
  const globalNotification = useSelector(selectGlobalNotification);
  dispatch(setSetting());

  useEffect(() => {
    const userStoredInformation = JSON.parse(localStorage.getItem("authenticatedUser"));
    if (userStoredInformation) {
      dispatch(setAuth({
        userInfo: userStoredInformation.data,
        authToken: userStoredInformation.token,
      }));
    } else {
      dispatch(clearAuth());
    }
  }, [dispatch]);

  useEffect(() => {
    if (globalError) {
      const timeoutId = setTimeout(() => {
        dispatch(clearGlobalError());
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [globalError, dispatch]);

  useEffect(() => {
    if (globalNotification) {
      const timeoutId = setTimeout(() => {
        dispatch(clearGlobalNotification());
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [globalNotification, dispatch]);


  return (
    <div className="App">
      {globalError && (
        <Alert
          variant="alert alert-solid alert-danger"
          className="d-flex align-items-center slide-in-right "
          role="alert"
          style={{
            right: "10px",
            top: "75px",
            zIndex: "1000",
            position: "fixed",
          }}
        >
          <span className="d-flex me-2">
            <i className="material-symbols-outlined">notifications</i>
          </span>
          <div>{globalError}</div>
        </Alert>
      )}
      {globalNotification && !globalError && (
        <Alert
          variant="alert alert-solid alert-success"
          className="d-flex align-items-center slide-in-right"
          role="alert"
          style={{
            right: "10px",
            top: "75px",
            zIndex: "1000",
            position: "fixed",
          }}
        >
          <span className="d-flex me-2">
            <i className="material-symbols-outlined font-size-20">
              notifications
            </i>
          </span>
          <div>{globalNotification}</div>
        </Alert>
      )}
      {/* <IndexRouters /> */}
      {children}
    </div>
  );
}

export default App;
