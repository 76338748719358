import React, { useEffect } from "react";
import { Row, Col, Card, Nav, Tab } from "react-bootstrap";
import ManageUsers from "./manage/user";
import ManageCompanies from "./manage/company";
import ManageEvent from "./manage/event";
import ManageNews from "./manage/news";
import ManageOverallStats from "./manage/stats";
import { jwtDecode } from "jwt-decode";
import { selectAuthToken } from "../../../store/auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setGlobalError } from "../../../store/error/error";

const AdminMain = () => {
  const authToken = useSelector(selectAuthToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      const decoded = jwtDecode(authToken);
      if (decoded?.userType != "2") {
        navigate("/dashboard");
        dispatch(
          setGlobalError(
            "Access denied ! " + "you don't have to the protected resource."
          )
        );
      }
    }
  }, [authToken]);

  return (
    <>
      <div id="content-page" className="custom-container">
        <Row>
          <Col sm="24" lg="24">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Admin Operation</h4>
                </div>
              </Card.Header>
              <Card.Body className="pt-0">
                <Tab.Container defaultActiveKey="stats">
                  <Nav
                    as="ul"
                    variant="pills"
                    className="mb-3 nav-fill"
                    id="pills-tab-1"
                    role="tablist"
                  >
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        eventKey="stats"
                        className="nav-link"
                        data-bs-toggle="pill"
                        href="#stats"
                        role="tab"
                        aria-controls="stats"
                        aria-selected="true"
                      >
                        Statistics
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        eventKey="users"
                        className="nav-link"
                        data-bs-toggle="pill"
                        href="#users"
                        role="tab"
                        aria-controls="users"
                        aria-selected="true"
                      >
                        Users
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        eventKey="companies"
                        className="nav-link"
                        data-bs-toggle="pill"
                        href="#companies"
                        role="tab"
                        aria-controls="companies"
                        aria-selected="false"
                      >
                        Companies
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        eventKey="events"
                        className="nav-link"
                        data-bs-toggle="pill"
                        href="#events"
                        role="tab"
                        aria-controls="events"
                        aria-selected="false"
                      >
                        Events
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        eventKey="news"
                        className="nav-link"
                        data-bs-toggle="pill"
                        href="#news"
                        role="tab"
                        aria-controls="news"
                        aria-selected="false"
                      >
                        News
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <hr />
                  <Tab.Content className="tab-content" id="pills-tabContent-1">
                    <Tab.Pane
                      eventKey="stats"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab-fill"
                    >
                      <ManageOverallStats />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="users"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab-fill"
                    >
                      <ManageUsers />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="companies"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab-fill"
                    >
                      <ManageCompanies />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="events"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab-fill"
                    >
                      <ManageEvent />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="news"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab-fill"
                    >
                      <ManageNews />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdminMain;
