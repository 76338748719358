import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Form,
  Badge,
  Dropdown,
} from "react-bootstrap";
import Card from "../../../../components/Card";
import { Link, useNavigate } from "react-router-dom";
import pageimg1 from "../../../../assets/images/page-img/43.png";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthToken, selectUserInfo } from "../../../../store/auth/auth";
import axios from "axios";
import { setGlobalError } from "../../../../store/error/error";
import { setGlobalNotification } from "../../../../store/notification/notification";
import CreateNewsDialog from "../../modals/create-news";

const ManageNews = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const userObject = useSelector(selectUserInfo);
  const [searchKey, setSearchKey] = useState("");
  const authToken = useSelector(selectAuthToken);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    if (authToken) {
      const fetchNewsList = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/news`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          setNewsData(response.data.data);
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      };
      fetchNewsList();
    }
  }, [authToken]);

  const deleteNews = async (news) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/news/${news.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // Locally Removing User From Records
      let newsDataCopy = [...newsData];
      newsDataCopy = newsDataCopy.filter((x) => x?.id != news.id);
      setNewsData(newsDataCopy);
      dispatch(setGlobalNotification("News deleted successfully !"));
      //
    } catch (error) {
      console.error("Error : ", error);
      dispatch(setGlobalError("Error ! Deleting News."));
    }
  };

  return (
    <>
      <Row>
        <Col sm="24">
          <Card>
            <Card.Body className="pt-0">
              <Row className="justify-content-between d-flex">
                <Col sm="24" md="6">
                  <div
                    id="user_list_datatable_info"
                    className="dataTables_filter"
                  >
                    <form className="me-3 position-relative">
                      <Dropdown
                        bsPrefix=" "
                        className="iq-search-bar device-search position-relative d-none d-lg-block"
                      >
                        <Dropdown.Toggle
                          as="form"
                          bsPrefix=" "
                          action="#"
                          className="searchbox open-modal-search"
                        >
                          <Link className="search-link" to="#">
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="7.82491"
                                cy="7.82495"
                                r="6.74142"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></circle>
                              <path
                                d="M12.5137 12.8638L15.1567 15.5"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </Link>
                          <input
                            type="text"
                            className="text search-input form-control bg-light-subtle"
                            placeholder="Search News"
                            value={searchKey}
                            onChange={(e) => setSearchKey(e.target.value)}
                          />
                          <Link
                            className="d-lg-none d-flex d-none d-lg-block"
                            to="/"
                          >
                            <span className="material-symbols-outlined">
                              search12
                            </span>
                          </Link>
                        </Dropdown.Toggle>
                      </Dropdown>
                    </form>
                  </div>
                </Col>
                <Col sm="24" md="6">
                  <div className="user-list-files d-flex justify-content-end">
                    <CreateNewsDialog showTextButton={true} />
                    {/* <Link
                      to="#"
                      className="chat-icon-video btn btn-primary-subtle"
                    >
                      Excel
                    </Link>{" "}
                    <Link
                      to="#"
                      className="cchat-icon-delete btn btn-primary-subtle"
                    >
                      Pdf
                    </Link>{" "} */}
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <table className="files-lists table table-striped mt-4 text-center">
                  <thead>
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">News URL</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newsData
                      .filter((x) =>
                        x?.title
                          ?.toLowerCase()
                          .includes(searchKey?.toLowerCase())
                      )
                      .map((news) => (
                        <tr>
                          <td className="text-start">
                            <img
                              className="rounded-circle img-fluid avatar-40 me-2 object-fit"
                              src={
                                news && news.newsBannerUrl
                                  ? news?.newsBannerUrl
                                  : pageimg1
                              }
                              alt="profile"
                            />{" "}
                            {news?.title}
                          </td>
                          <td>{new Date(news?.createdAt).toDateString()}</td>
                          <td>
                            <a href={news?.link}>Link</a>
                          </td>
                          <td>
                            <div className="flex align-items-center list-user-action lh-1">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <Link to="#" onClick={() => deleteNews(news)}  className="text-danger">
                                  <i className="material-symbols-outlined">
                                    delete
                                  </i>
                                </Link>
                              </OverlayTrigger>{" "}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ManageNews;
