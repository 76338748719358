import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  Container,
  Col,
  Row,
  Card,
  Form,
  Nav,
  TabContent,
  TabPane,
  Button,
  Image,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import image1 from "../../../assets/images/page-img/img-success.png";
import { setGlobalError, clearGlobalError } from "../../../store/error/error";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthToken, selectUserInfo } from "../../../store/auth/auth";
import { setAuth } from "../../../store/auth/auth";
import { setGlobalNotification } from "../../../store/notification/notification";
import { CONTACT_VALIDATION_REGEX } from "../../../utilities/regex";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = useSelector(selectAuthToken);
  const [show, AccountShow] = useState("user");
  const [index, setIndex] = useState(0);
  const wizards = ["user", "document", "confirm", "experience"];
  const [previewPic, setPreviewPic] = useState("");
  const userObject = useSelector(selectUserInfo);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [rolesData, setRolesData] = useState([]);
  const [userAssociation, setUserAssociation] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: userObject?.firstName || "",
      lastName: userObject?.lastName || "",
      currentRole: userObject?.currentRole || "",
      email: userObject?.email || "",
      phoneNumber: userObject?.phoneNumber || "",
      alternateContactNumber: userObject?.contactNumber || "",
      profileDescription: userObject?.profileDescription || "",
      twitterProfileUrl: userObject?.twitterProfileUrl || "",
      linkedInProfileUrl: userObject?.linkedInProfileUrl || "",
      personalWebsite: userObject?.personalWebsite || "",
      profilePictureUrl: userObject?.profilePictureUrl || "",
      dateOfBirth: userObject?.dateOfBirth || "",
      gender: userObject?.gender || "",
    },
  });
  const experienceForm = useForm();

  useEffect(() => {
    if (authToken) {
      getAllRoles();
      getUserCompanyAssociation();
    }
  }, [authToken]);

  const profilePictureUrl = watch("profilePictureUrl");

  useEffect(() => {
    if (profilePictureUrl) {
      setPreviewPic(profilePictureUrl);
    }
  }, [profilePictureUrl]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    const imageUrl = URL.createObjectURL(event.target.files[0]);
    setPreviewPic(imageUrl);
  };

  const handleCompanyChange = (selected) => {
    setSelectedCompany(selected);
  };

  const getAllRoles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/role`,
        {
          headers: {
            Authorization: `${process.env.REACT_APP_AUTH_NAME} ${authToken}`,
          },
        }
      );
      setRolesData(
        response.data.data.map((obj) => ({ label: obj.name, value: obj.id }))
      );
    } catch (error) {
      console.log(error);
      dispatch(setGlobalError("Error ! Cannot fetch roles data."));
    }
  };

  const getUserCompanyAssociation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/userCompanyRole/user/${userObject?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setUserAssociation(response.data.data);
    } catch (error) {
      console.error("Error ! Fetching data for user company association.");
    }
  };

  const searchCompanies = async (query, callback) => {
    if (!query) {
      return callback([]);
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company/search?keyword=${query}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      callback(
        response.data.data.map((obj) => ({ label: obj.name, value: obj.id }))
      );
    } catch (error) {
      console.error("Error fetching options from API", error);
      callback([]);
    }
  };

  const uploadProfileImage = async () => {
    const formData = new FormData();
    formData.append("profileImage", selectedFile);
    try {
      if (selectedFile) {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/user/uploadProfileImage/${userObject?.id}`,
          formData,
          {
            headers: {
              Authorization: `${process.env.REACT_APP_AUTH_NAME} ${authToken}`,
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(setGlobalError("Error ! uploading profile image"));
    }
  };

  const submitUserExperience = async (data) => {
    setLoading(true);
    let payloadObject = {
      userId: userObject?.id,
      companyId: selectedCompany.value,
      roleId: selectedRole.value,
      endDate: data.endDate ? data.endDate : false,
      startDate: data.startDate ? data.startDate : false,
      isBoardMember: data.isBoardMember ? data.isBoardMember : false,
      isCurrent: data.isCurrent ? data.isCurrent : false,
    };

    if (payloadObject.isCurrent) {
      delete payloadObject.endDate;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/userCompanyRole`,
        payloadObject,
        {
          headers: {
            Authorization: `${process.env.REACT_APP_AUTH_NAME} ${authToken}`,
          },
        }
      );
      setLoading(false);
      getUserCompanyAssociation();
      dispatch(setGlobalNotification("Association added successfully !"));
    } catch (error) {
      console.log(error);
      setLoading(false);
      dispatch(setGlobalError("Error ! Cannot add user company role mapping."));
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    if (index != 2 || !userObject?.id) return;
    try {
      dispatch(clearGlobalError());
      await uploadProfileImage();

      let payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        currentRole: data.currentRole,
        phoneNumber: data.phoneNumber,
        profileDescription: data.profileDescription,
        twitterProfileUrl: data.twitterProfileUrl,
        linkedInProfileUrl: data.linkedInProfileUrl,
        profilePictureUrl: data.profilePictureUrl,
        dateOfBirth: data.dateOfBirth,
        gender: data.gender,
      };

      if (selectedFile) delete payload.profilePictureUrl;

      let response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/user/${userObject?.id}`,
        payload,
        {
          headers: {
            Authorization: `${process.env.REACT_APP_AUTH_NAME} ${authToken}`,
          },
        }
      );

      dispatch(
        setAuth({
          userInfo: response.data.data,
          authToken: authToken,
        })
      );
      dispatch(
        setGlobalNotification("Profile information updated successfully !")
      );
      setLoading(false);
      setIndex(index + 1);
      AccountShow(wizards[index + 1]);
      getUserCompanyAssociation();
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 401) {
        dispatch(
          setGlobalError("Error! Unauthorized. Please check your credentials.")
        );
      } else if (error.response?.data.message) {
        dispatch(setGlobalError("Error! " + error.response?.data.message));
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  const deleteUserCompanyRoleAssociation = async (associationId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/userCompanyRole/user/${userObject?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getUserCompanyAssociation();
    } catch (error) {
      console.error("Error ! Deleting data for user company association.");
    }
  };

  const getRoleName = (roleId) => {
    return rolesData.filter((x) => x.value == roleId)[0].label;
  };

  const getCountryName = (companyId) => {
    // return .filter((x) => x.value == roleId)[0].label;
  };

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white"> Profile Setup</h3>
                  <p className="text-white">
                    Update your profile information to get relevant suggestion
                    and best content
                  </p>
                </div>
              </Card>
            </Col>
            <Col sm="12" lg="12">
              <Card className="pt-5">
                <Card.Body className="pt-0">
                  <Form id="registration">
                    <Nav
                      fill
                      variant="pills"
                      className="stepwizard-row"
                      id="nav-tab"
                      role="tablist"
                    >
                      <Nav.Link
                        className={` ${
                          show === "user" ? "active done" : ""
                        }  btn`}
                        id="basic-information-tab"
                        data-toggle="tab"
                        href="#basic-information"
                      >
                        {/* <i className="material-symbols-outlined bg-primary-subtle text-primary">
                          lock_open
                        </i> */}
                        <span>Basic Detail</span>
                      </Nav.Link>
                      <Nav.Link
                        className={` ${
                          show === "document" ? "active done" : ""
                        } btn`}
                        id="social-information-tab"
                        data-toggle="tab"
                        href="#social-information"
                      >
                        {/* <i className="material-symbols-outlined bg-danger-subtle text-danger">
                          person
                        </i> */}
                        <span>Social Profile</span>
                      </Nav.Link>
                      <Nav.Link
                        className={` ${
                          show === "confirm1" ? "active done" : ""
                        } btn`}
                        id="profile-picture-tab"
                        data-toggle="tab"
                        href="#profile-picture"
                      >
                        {/* <i className="material-symbols-outlined bg-warning-subtle text-warning">
                          done
                        </i> */}
                        <span>Upload & Confirm</span>
                      </Nav.Link>
                      <Nav.Link
                        className={` ${
                          show === "confirm2" ? "active done" : ""
                        } btn`}
                        id="profile-picture-tab"
                        data-toggle="tab"
                        href="#profile-picture"
                      >
                        {/* <i className="material-symbols-outlined bg-warning-subtle text-warning">
                          done
                        </i> */}
                        <span>Experience</span>
                      </Nav.Link>
                    </Nav>
                    <TabContent className="pt-4 pb-2" id="nav-tabContent">
                      <TabPane
                        className={` ${
                          show === "user" ? "d-block" : "d-none"
                        } fade row show`}
                        id="basic-information"
                      >
                        <Col sm="12">
                          <Col md="12" className="p-0">
                            <h3 className="mb-4">Basic Information:</h3>
                            <Row>
                              <Form.Group className=" col-md-6 form-group">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...register("firstName", { required: true })}
                                  placeholder="Enter First Name"
                                  defaultValue={userObject?.firstName}
                                />
                                {errors.firstName && (
                                  <span>This field is required</span>
                                )}
                              </Form.Group>
                              <Form.Group className=" col-md-6 form-group">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...register("lastName", { required: true })}
                                  placeholder="Enter Last Name"
                                  defaultValue={userObject?.lastName}
                                />
                                {errors.lastName && (
                                  <span>This field is required</span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group ">
                                <Form.Label>Current Role/Occupation</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...register("currentRole")}
                                  placeholder="Enter Your Current Role / Occupation"
                                  defaultValue={userObject?.currentRole}
                                />
                                {errors.currentRole && (
                                  <span>This field is required </span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group ">
                                <Form.Label>Email Id: *</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...register("email")}
                                  placeholder="Email ID"
                                  defaultValue={userObject?.email}
                                  disabled
                                />
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group ">
                                <Form.Label>Contact Number: *</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...register("phoneNumber", {
                                    value: CONTACT_VALIDATION_REGEX,
                                    message: "Invalid Contact Number Format",
                                  })}
                                  placeholder="Contact Number"
                                  defaultValue={userObject?.phoneNumber}
                                />
                                {errors.phoneNumber && (
                                  <span>{errors.phoneNumber.message}</span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group ">
                                <Form.Label>
                                  Alternate Contact Number: *
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  {...register("alternateContactNumber")}
                                  placeholder="Alternate Contact Number"
                                  defaultValue={userObject?.phoneNumber}
                                />
                                {errors.alternateContactNumber && (
                                  <span>This field is required</span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group ">
                                <Form.Label>Gender</Form.Label>
                                <select
                                  className="form-select"
                                  id="gender"
                                  {...register("gender", {
                                    required: "Gender is required",
                                  })}
                                  placeholder="Select Gender"
                                  defaultValue={userObject?.gender}
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Others">Other</option>
                                </select>
                                {errors.gender && (
                                  <span className="text-danger">
                                    {errors.gender.message}
                                  </span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group ">
                                <Form.Label>Date of birth</Form.Label>
                                <Form.Control
                                  type="date"
                                  {...register("dateOfBirth", {
                                    required: "Date of birth is required",
                                  })}
                                  defaultValue={userObject?.dateOfBirth}
                                />
                                {errors.dateOfBirth && (
                                  <p className="text-danger">
                                    {errors.dateOfBirth.message}
                                  </p>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-12 form-group mb-3">
                                <Form.Label>Profile Description: *</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  {...register("profileDescription")}
                                  rows="5"
                                  defaultValue={userObject?.profileDescription}
                                ></Form.Control>
                                {errors.profileDescription && (
                                  <span>This field is required</span>
                                )}
                              </Form.Group>
                            </Row>
                          </Col>
                        </Col>
                      </TabPane>
                      <TabPane
                        className={` ${
                          show === "document" ? "d-block" : "d-none"
                        } row show fade`}
                        id="social-information"
                      >
                        <Col sm="12">
                          <Col md="12" className="p-0">
                            <h3 className="mb-4">Social Profile Details</h3>
                            <Row>
                              <Form.Group className="form-group col-md-12">
                                <Form.Label>Twitter / X Profile URL</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...register("twitterProfileUrl")}
                                  defaultValue={userObject?.twitterProfileUrl}
                                  placeholder="Twitter Profile URL"
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-md-12 mb-0">
                                <div className="form-group">
                                  <Form.Label>LinkedIn Profile URL</Form.Label>
                                  <Form.Control
                                    type="text"
                                    {...register("linkedInProfileUrl")}
                                    defaultValue={
                                      userObject?.linkedInProfileUrl
                                    }
                                    placeholder="LinkedIn Profile URL"
                                  />
                                </div>
                              </Form.Group>
                              <Form.Group className="form-group col-md-12">
                                <div className="form-group">
                                  <Form.Label>Personal Webiste</Form.Label>
                                  <Form.Control
                                    type="text"
                                    {...register("personalWebsite")}
                                    defaultValue={
                                      userObject?.linkedInProfileUrl
                                    }
                                    placeholder="Personal Website"
                                  />
                                </div>
                              </Form.Group>
                            </Row>
                          </Col>
                        </Col>
                      </TabPane>
                      <TabPane
                        className={` ${
                          show === "confirm" ? "d-block" : "d-none"
                        } row show fade`}
                        id="profile-picture"
                      >
                        <Col sm="12">
                          <Col md="12" className="p-0">
                            <h3 className="mb-4 text-left">Finish:</h3>
                            <Row className="justify-content-center align-items-center flex-column">
                              <div className="col-3">
                                <Image
                                  src={previewPic ? previewPic : image1}
                                  className="img-fluid object-fit-cover"
                                  alt="img-success"
                                  style={{ width: "200px", height: "200px" }}
                                />
                              </div>
                              <Form.Group className="form-group">
                                <Form.Label className="custom-file-input">
                                  Choose Profile Picture
                                </Form.Label>{" "}
                                <Form.Control
                                  type="file"
                                  id="customFile"
                                  onChange={handleFileChange}
                                />
                              </Form.Group>
                            </Row>
                          </Col>
                        </Col>
                      </TabPane>
                      <TabPane
                        className={` ${
                          show === "experience" ? "d-block" : "d-none"
                        } row show fade`}
                        id="profile-picture"
                      >
                        <Col sm="12">
                          <hr />
                          <Col md="12" className="p-0">
                            <h3 className="mb-4">
                              Add Your Experience or Association:
                            </h3>
                            <Row>
                              <Form.Group className="col-md-4 form-group">
                                <Form.Label htmlFor="choices-multiple-default">
                                  Role/Designation
                                </Form.Label>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  defaultValue={selectedRole}
                                  isClearable
                                  options={rolesData}
                                  onChange={setSelectedRole}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-4 form-group">
                                <Form.Label htmlFor="choices-multiple-default">
                                  Company
                                </Form.Label>
                                <AsyncSelect
                                  className="basic-single"
                                  classNamePrefix="select"
                                  cacheOptions
                                  loadOptions={searchCompanies}
                                  defaultOptions
                                  onChange={setSelectedCompany}
                                  value={selectedCompany}
                                  placeholder="Type to search..."
                                />
                              </Form.Group>
                              <Form.Group className="col-md-4 form-group">
                                <div className="mt-5">
                                  <Button
                                    variant="primary-subtle"
                                    onClick={() =>
                                      navigate("/dashboard/company/create")
                                    }
                                  >
                                    Cannot find company ? Create New
                                  </Button>
                                </div>
                              </Form.Group>
                              <Form.Group className="col-md-4 form-group ">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                  type="date"
                                  {...experienceForm.register("startDate", {
                                    required: "Start date is required",
                                  })}
                                />
                                {experienceForm.formState?.errors
                                  ?.startDate && (
                                  <p className="text-danger">
                                    {
                                      experienceForm.formState?.errors
                                        ?.startDate.message
                                    }
                                  </p>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-4 form-group ">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                  type="date"
                                  {...experienceForm.register("endDate")}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-4 form-group">
                                <Form.Label></Form.Label>
                                <Form.Check className="col-md-12 form-check form-checkbox form-check-inline me-3">
                                  <Form.Check.Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheck-1"
                                    {...experienceForm.register("isCurrent")}
                                  />
                                  <Form.Check.Label className="form-check-label">
                                    {" "}
                                    Are you currently working here ?
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="col-md-12 form-check form-checkbox form-check-inline me-3">
                                  <Form.Check.Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheck-2"
                                    {...experienceForm.register(
                                      "isBoardMember"
                                    )}
                                  />
                                  <Form.Check.Label className="form-check-label">
                                    {" "}
                                    Are you a board member ?
                                  </Form.Check.Label>
                                </Form.Check>
                              </Form.Group>
                            </Row>
                          </Col>
                          <Col
                            sm="12"
                            className="d-flex justify-content-end mt-5"
                          >
                            <Button
                              variant="success"
                              onClick={experienceForm.handleSubmit(
                                submitUserExperience
                              )}
                            >
                              {loading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                "Add Experience"
                              )}
                            </Button>
                          </Col>
                          <div className="table-responsive">
                            <table className="files-lists table table-striped mt-4 text-start">
                              <thead>
                                <tr>
                                  <th scope="col">Company Name</th>
                                  <th scope="col">Designation</th>
                                  <th scope="col">Start Date</th>
                                  <th scope="col">End Date</th>
                                  <th scope="col">Board Member</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userAssociation.map((exp) => (
                                  <tr>
                                    <td className="text-start">
                                      <img
                                        className="rounded-circle img-fluid avatar-40 me-2 object-fit"
                                        src={
                                          exp && exp.company
                                            ? exp.company?.companyLogoUrl
                                            : ""
                                        }
                                        alt="profile"
                                      />{" "}
                                      {exp.company.name}
                                    </td>
                                    <td className="text-start">
                                      {getRoleName(exp.roleId)}
                                    </td>
                                    <td>
                                      {new Date(exp?.startDate).toDateString()}
                                    </td>
                                    <td>
                                      {exp?.endDate
                                        ? new Date(exp?.endDate).toDateString()
                                        : "-"}
                                    </td>
                                    <td>{exp?.isBoardMember ? "Yes" : "No"}</td>
                                    <td>
                                      <div className="flex align-items-center list-user-action lh-1">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>Delete</Tooltip>}
                                        >
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              deleteUserCompanyRoleAssociation(
                                                exp?.div
                                              )
                                            }
                                            className="text-danger"
                                          >
                                            <i className="material-symbols-outlined">
                                              delete
                                            </i>
                                          </Link>
                                        </OverlayTrigger>{" "}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <hr />
                        </Col>
                      </TabPane>
                    </TabContent>
                    <div
                      className={`row ${
                        show !== "user"
                          ? "justify-content-between"
                          : "flex-row-reverse"
                      }`}
                    >
                      {show !== "user" ? (
                        <div className="col-auto">
                          <Button
                            variant="secondary"
                            onClick={() => {
                              setIndex(index - 1);
                              AccountShow(wizards[index - 1]);
                            }}
                          >
                            Previous
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                      {show === "confirm" ? (
                        <div className="col-auto">
                          <Button
                            variant="primary"
                            onClick={handleSubmit(onSubmit)}
                          >
                            {loading ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Save & Next"
                            )}
                          </Button>
                        </div>
                      ) : (
                        <>
                          {show == "experience" ? (
                            <div className={`col-auto `}>
                              <Button
                                variant="primary"
                                onClick={() => {
                                  navigate("/dashboard");
                                }}
                              >
                                Exit
                              </Button>
                            </div>
                          ) : (
                            <div className={`col-auto `}>
                              <Button
                                variant="primary"
                                onClick={() => {
                                  setIndex(index + 1);
                                  AccountShow(wizards[index + 1]);
                                }}
                              >
                                Next
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProfileSettings;
