import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  Container,
  Col,
  Row,
  Card,
  Form,
  TabContent,
  TabPane,
  Button,
  Image,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthToken, selectUserInfo } from "../../../store/auth/auth";
import { setGlobalError, clearGlobalError } from "../../../store/error/error";
import image1 from "../../../assets/images/page-img/img-success.png";
import {
  CONTACT_VALIDATION_REGEX,
  URL_VALIDATION_REGEX,
} from "../../../utilities/regex";

const CreateCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = useSelector(selectAuthToken);
  const userObject = useSelector(selectUserInfo);
  const [loading, setLoading] = useState(false);
  const [show, AccountShow] = useState("Overview");
  const [index, setIndex] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentCompany, setCurrentCompany] = useState("");
  const [industryData, setIndustryData] = useState([]);
  const wizards = [
    "Overview",
    "Address",
    "IPO & Stocks",
    "Funding Rounds",
    "Acquisitions",
    "Events",
    "News",
  ];
  const overviewForm = useForm();
  const addressForm = useForm();
  const ipoStocksForm = useForm();
  const fundingRoundsForm = useForm();
  const acquisitionsForm = useForm();
  const eventsForm = useForm();
  const newsForm = useForm();

  useEffect(() => {
    if (authToken) {
      getIndustryTypeData();
    }
  }, [authToken]);

  // Capture file input change
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    const imageUrl = URL.createObjectURL(event.target.files[0]);
    overviewForm.setValue("companyLogoUrl", imageUrl);
  };

  const getIndustryTypeData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/industry`,
        {
          headers: {
            Authorization: `${process.env.REACT_APP_AUTH_NAME} ${authToken}`,
          },
        }
      );
      setIndustryData(response.data.data);
    } catch (error) {
      console.log(error);
      dispatch(setGlobalError("Error ! getting industry type data."));
    }
  };

  const uploadCompanyLogo = async (companyId) => {
    const formData = new FormData();
    formData.append("companyLogo", selectedFile);
    try {
      if (selectedFile) {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/company/uploadCompanyLogo/${companyId}`,
          formData,
          {
            headers: {
              Authorization: `${process.env.REACT_APP_AUTH_NAME} ${authToken}`,
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(setGlobalError("Error ! uploading company logo."));
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let apiRoute = "";
    let formData = data;
    switch (show) {
      case "Overview":
        apiRoute = "company";
        break;
      case "Address":
        apiRoute = "address";
        break;
      case "IPO & Stocks":
        apiRoute = "ipo";
        break;
      case "Funding Rounds":
        apiRoute = "funding";
        break;
      case "Acquisitions":
        apiRoute = "acquisition";
        break;
      case "Events":
        apiRoute = "event";
        break;
      case "News":
        apiRoute = "news";
        break;
      default:
        console.error("Unknown step:", show);
    }

    if (currentCompany && show != "Overview") {
      formData = {
        companyId: currentCompany,
        ...formData,
      };
    } else {
      formData = {
        createdBy: userObject?.id,
        ...formData,
      };
    }

    try {
      dispatch(clearGlobalError());
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/${apiRoute}`,
        formData,
        {
          headers: {
            Authorization: `${process.env.REACT_APP_AUTH_NAME} ${authToken}`,
          },
        }
      );

      if (show == "Overview") {
        setCurrentCompany(response.data.data.id);
        if (selectedFile) {
          uploadCompanyLogo(response.data.data.id);
        }
      }

      setIndex(index + 1);
      AccountShow(wizards[index + 1]);

      if (show == "News") {
        navigate("/dashboard/company/search");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 401) {
        dispatch(
          setGlobalError("Error! Unauthorized. Please check your credentials.")
        );
      } else if (error.response?.data.message) {
        dispatch(setGlobalError("Error! " + error.response?.data.message));
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  const submitMultipleForm = () => {
    switch (show) {
      case "Overview":
        overviewForm.handleSubmit(onSubmit)();
        break;
      case "Address":
        addressForm.handleSubmit(onSubmit)();
        break;
      case "IPO & Stocks":
        ipoStocksForm.handleSubmit(onSubmit)();
        break;
      case "Funding Rounds":
        fundingRoundsForm.handleSubmit(onSubmit)();
        break;
      case "Acquisitions":
        acquisitionsForm.handleSubmit(onSubmit)();
        break;
      case "Events":
        eventsForm.handleSubmit(onSubmit)();
        break;
      case "News":
        newsForm.handleSubmit(onSubmit)();
        break;
      default:
        console.error("Unknown step:", show);
    }
  };

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">Create Company</h3>
                  <p className="text-white">
                    Create a new company profile by filling up details on each
                    step of the form
                  </p>
                </div>
              </Card>
            </Col>
            <Col sm="12" lg="12">
              <Card className="pt-5">
                <Card.Body className="pt-0">
                  <ul id="top-tab-list" className="p-0 row list-inline">
                    {wizards.map((step, idx) => (
                      <li
                        className={`text-center ${show == step && "active"}`}
                        id="account"
                        onClick={() => AccountShow(step)}
                        style={{ width: "14%" }}
                      >
                        <Link to="#">
                          <span className="font-size-14">
                            {step.charAt(0).toUpperCase() + step.slice(1)}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <TabContent className="pt-4 pb-2" id="nav-tabContent">
                    <TabPane
                      className={`${
                        show === "Overview" ? "d-block" : "d-none"
                      } fade row show`}
                      id="basic-info"
                    >
                      <Form>
                        <Col sm="12">
                          <Col md="12" className="p-0">
                            <h3 className="mb-4 text-left">Overview</h3>
                            <Row className="justify-content-center align-items-center flex-column">
                              <div className="col-3">
                                <Image
                                  src={
                                    overviewForm.getValues("companyLogoUrl")
                                      ? overviewForm.getValues("companyLogoUrl")
                                      : image1
                                  }
                                  className="img-fluid object-fit-cover"
                                  alt="company-logo"
                                  style={{ width: "200px", height: "200px" }}
                                />
                              </div>
                              <Form.Group className="form-group">
                                <Form.Label className="custom-file-input">
                                  Select Company Logo
                                </Form.Label>{" "}
                                <Form.Control
                                  type="file"
                                  id="customFile"
                                  onChange={handleFileChange}
                                />
                              </Form.Group>
                            </Row>
                          </Col>
                        </Col>
                        <Col sm="12">
                          <Col md="12" className="p-0">
                            <h3 className="mb-4">Basic Information:</h3>
                            <Row>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>
                                  Company Name
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  {...overviewForm.register("name", {
                                    required: true,
                                  })}
                                  placeholder="Enter Company Name"
                                />
                                {overviewForm.formState?.errors?.name && (
                                  <span className="text-danger">
                                    This field is required
                                  </span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label
                                  className="form-label"
                                  htmlFor="company-type"
                                >
                                  Company Type
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <select
                                  className="form-select"
                                  id="company-type"
                                  {...overviewForm.register("companyType", {
                                    required: true,
                                  })}
                                  placeholder="Enter Company Type"
                                >
                                  <option defaultValue value="For Profit">
                                    For Profit
                                  </option>
                                  <option value="Non-profit">Non-profit</option>
                                </select>
                                {overviewForm.formState?.errors
                                  ?.companyType && (
                                  <span className="text-danger">
                                    This field is required
                                  </span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label
                                  className="form-label"
                                  htmlFor="company-type"
                                >
                                  Founder Category
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <select
                                  className="form-select"
                                  id="founder-category"
                                  {...overviewForm.register("founderCategory")}
                                  placeholder="Select Founder Category"
                                >
                                  <option defaultValue value="Black-founded">
                                    Black-founded
                                  </option>
                                  <option value="Hispanic & Latino-founded">
                                    Hispanic & Latino-founded
                                  </option>
                                  <option value="Women-founded">
                                    Women-founded
                                  </option>
                                  <option value="Indigenous-founded">
                                    Indigenous-founded
                                  </option>
                                  <option value="Veteran-founded">
                                    Veteran-founded
                                  </option>
                                  <option value="Asian-founded">
                                    Asian-founded
                                  </option>
                                  <option value="Disability-founded">
                                    Disability-founded
                                  </option>
                                  <option value="Immigrant-founded">
                                    Immigrant-founded
                                  </option>
                                  <option value="Minority-founded">
                                    Minority-founded
                                  </option>
                                  <option value="First-generation-founded">
                                    First-generation-founded
                                  </option>
                                  <option value="Youth-founded">
                                    Youth-founded
                                  </option>
                                  <option value="Senior-founded">
                                    Senior-founded
                                  </option>
                                </select>
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label
                                  className="form-label"
                                  htmlFor="company-type"
                                >
                                  Industry Type
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <select
                                  className="form-select"
                                  id="industry-type"
                                  {...overviewForm.register("industryTypeId")}
                                  placeholder="Select Industry Type"
                                >
                                  {industryData.map((type) => (
                                    <option defaultValue value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </select>
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>Number of Founders</Form.Label>
                                <Form.Control
                                  type="number"
                                  {...overviewForm.register(
                                    "numberOfFounders"
                                  )}
                                  placeholder="Enter number of founders"
                                />
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>Number of Board Members</Form.Label>
                                <Form.Control
                                  type="number"
                                  {...overviewForm.register(
                                    "numberOfBoardMembers"
                                  )}
                                  placeholder="Enter number of board members"
                                />
                              </Form.Group>
                              <Form.Group className="col-md-12 form-group">
                                <Form.Label>Tagline</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...overviewForm.register("tagLine")}
                                  placeholder="Enter Tagline"
                                />
                              </Form.Group>
                            </Row>
                          </Col>
                        </Col>
                        <Col sm="12">
                          <Col md="12" className="p-0">
                            <h3 className="mb-4">Social Information:</h3>
                            <Row>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>
                                  Website URL
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="url"
                                  {...overviewForm.register("websiteUrl", {
                                    pattern: {
                                      value: URL_VALIDATION_REGEX,
                                      message: "Invalid URL format",
                                    },
                                    required: true,
                                  })}
                                  placeholder="Enter Website URL"
                                />
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>Facebook URL</Form.Label>
                                <Form.Control
                                  type="url"
                                  {...overviewForm.register("facebookUrl", {
                                    pattern: {
                                      value: URL_VALIDATION_REGEX,
                                      message: "Invalid URL format",
                                    },
                                  })}
                                  placeholder="Enter Facebook URL"
                                />
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>Twitter URL</Form.Label>
                                <Form.Control
                                  type="url"
                                  {...overviewForm.register("twitterUrl", {
                                    pattern: {
                                      value: URL_VALIDATION_REGEX,
                                      message: "Invalid URL format",
                                    },
                                  })}
                                  placeholder="Enter Twitter URL"
                                />
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>LinkedIn URL</Form.Label>
                                <Form.Control
                                  type="url"
                                  {...overviewForm.register("linkedInUrl", {
                                    pattern: {
                                      value: URL_VALIDATION_REGEX,
                                      message: "Invalid URL format",
                                    },
                                  })}
                                  placeholder="Enter LinkedIn URL"
                                />
                              </Form.Group>
                            </Row>
                          </Col>
                        </Col>
                        <Col sm="12">
                          <Col md="12" className="p-0">
                            <h3 className="mb-4">Company Details:</h3>
                            <Row>
                              <Form.Group className="col-md-12 form-group">
                                <Form.Label>
                                  Description
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  {...overviewForm.register("description", {
                                    required: true,
                                  })}
                                  placeholder="Enter Company Description"
                                />
                                {overviewForm.formState?.errors
                                  ?.description && (
                                  <span className="text-danger">
                                    This field is required
                                  </span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...overviewForm.register("phoneNumber", {
                                    pattern: {
                                      value: CONTACT_VALIDATION_REGEX,
                                      message: "Invalid Contact Number Format",
                                    },
                                  })}
                                  placeholder="Enter Phone Number"
                                />
                                {overviewForm.formState?.errors
                                  ?.phoneNumber && (
                                  <span className="text-danger">
                                    This field is required
                                  </span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>
                                  Contact Email
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  {...overviewForm.register("contactEmail", {
                                    required: true,
                                  })}
                                  placeholder="Enter Contact Email"
                                />
                                {overviewForm.formState?.errors
                                  ?.contactEmail && (
                                  <span className="text-danger">
                                    This field is required
                                  </span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>Legal Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...overviewForm.register("legalName")}
                                  placeholder="Enter Legal Name"
                                />
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>Number of Employees</Form.Label>
                                <Form.Control
                                  type="number"
                                  {...overviewForm.register("numberOfEmployee")}
                                  placeholder="Enter Number of Employees"
                                />
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>Founded Date</Form.Label>
                                <Form.Control
                                  type="date"
                                  {...overviewForm.register("foundedDate")}
                                />
                              </Form.Group>
                              <Form.Group className="col-md-6 form-group">
                                <Form.Label>Closed Date</Form.Label>
                                <Form.Control
                                  type="date"
                                  {...overviewForm.register("closedDate")}
                                />
                              </Form.Group>
                            </Row>
                          </Col>
                        </Col>
                      </Form>
                    </TabPane>
                    <TabPane
                      className={`${
                        show === "Address" ? "d-block" : "d-none"
                      } fade row show`}
                      id="company-details"
                    >
                      <h3 className="mb-4 text-left">Headquater / Address</h3>

                      <Form>
                        <Col sm="12">
                          <Col md="12" className="p-0">
                            <Row>
                              <Form.Group className="col-md-12 form-group">
                                <Form.Label>
                                  Address Name
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  {...addressForm.register("addressName", {
                                    required: true,
                                  })}
                                  placeholder="Enter Company Name"
                                />
                                {addressForm.formState?.errors?.addressName && (
                                  <span className="text-danger">
                                    This field is required
                                  </span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-12 form-group">
                                <Form.Label>
                                  Address Line 1
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  {...addressForm.register("addressLineOne", {
                                    required: true,
                                  })}
                                  placeholder="Enter Address Line 1"
                                />
                                {addressForm.formState?.errors
                                  ?.addressLineOne && (
                                  <span className="text-danger">
                                    This field is required
                                  </span>
                                )}
                              </Form.Group>
                              <Form.Group className="col-md-12 form-group">
                                <Form.Label>Address Line 2</Form.Label>
                                <Form.Control
                                  type="text"
                                  {...addressForm.register("addressLineTwo")}
                                  placeholder="Enter Address Line 2"
                                />
                              </Form.Group>
                            </Row>
                          </Col>
                        </Col>
                        <Col sm="12">
                          <Col md="12" className="p-0">
                            <h3 className="mb-4">
                              Select the city for this address
                            </h3>
                            <Row>
                              <Form.Group className="col-md-12 form-group">
                                <Form.Label>
                                  City<span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="url"
                                  {...addressForm.register("city", {
                                    required: true,
                                  })}
                                  placeholder="Enter City"
                                />
                                {addressForm.formState?.errors?.city && (
                                  <span className="text-danger">
                                    This field is required
                                  </span>
                                )}
                              </Form.Group>
                            </Row>
                          </Col>
                        </Col>
                      </Form>
                    </TabPane>
                    <TabPane
                      className={`${
                        show === "IPO & Stocks" ? "d-block" : "d-none"
                      } fade row show`}
                      id="social-info"
                    >
                      <Form>
                        <Col sm="12">
                          <h3 className="mb-4">IPO Details</h3>
                          <Row>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Stock Symbol
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...ipoStocksForm.register("stockSymbol", {
                                  required: true,
                                })}
                                placeholder="Enter Stock Symbol"
                              />
                              {ipoStocksForm.formState?.errors?.stockSymbol && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Stock Exchange
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...ipoStocksForm.register("stockExchange")}
                                placeholder="Enter Stock Exchange"
                              />
                              {ipoStocksForm.formState?.errors
                                ?.stockExchange && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                IPO Date<span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                {...ipoStocksForm.register("ipoDate")}
                                placeholder="Enter IPO Date"
                              />
                              {ipoStocksForm.formState?.errors?.ipoDate && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>Delisted Date</Form.Label>
                              <Form.Control
                                type="date"
                                {...ipoStocksForm.register("delistedDate")}
                                placeholder="Enter Delisted Date"
                              />
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>Listing Type</Form.Label>
                              <Form.Control
                                type="text"
                                {...ipoStocksForm.register("listingType")}
                                placeholder="Enter Listing Type"
                              />
                              {ipoStocksForm.formState?.errors?.listingType && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>Valuation at IPO</Form.Label>
                              <Form.Control
                                type="number"
                                {...ipoStocksForm.register("valuation")}
                                placeholder="Enter Valuation at IPO"
                              />
                              {ipoStocksForm.formState?.errors?.valuation && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>Amount Raised</Form.Label>
                              <Form.Control
                                type="number"
                                {...ipoStocksForm.register("amountRaised")}
                                placeholder="Enter Amount Raised"
                              />
                              {ipoStocksForm.formState?.errors
                                ?.amountRaised && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>Share Price</Form.Label>
                              <Form.Control
                                type="number"
                                {...ipoStocksForm.register("sharePrice")}
                                placeholder="Enter Share Price"
                              />
                              {ipoStocksForm.formState?.errors?.sharePrice && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>Shares Sold</Form.Label>
                              <Form.Control
                                type="number"
                                {...ipoStocksForm.register("sharesSold")}
                                placeholder="Enter Shares Sold"
                              />
                              {ipoStocksForm.formState?.errors?.sharesSold && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>Amount Raised</Form.Label>
                              <Form.Control
                                type="number"
                                {...ipoStocksForm.register("sharesOutstanding")}
                                placeholder="Enter Shares Outstanding"
                              />
                              {ipoStocksForm.formState?.errors
                                ?.sharesOutstanding && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>Curreny</Form.Label>
                              <Form.Control
                                type="text"
                                {...ipoStocksForm.register("currency")}
                                placeholder="Enter Currency"
                              />
                              {ipoStocksForm.formState?.errors?.currency && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </Col>
                      </Form>
                    </TabPane>
                    <TabPane
                      className={`${
                        show === "Funding Rounds" ? "d-block" : "d-none"
                      } fade row show`}
                      id="confirm-info"
                    >
                      <Form>
                        <Col sm="12">
                          <h3 className="mb-4">Funding Rounds</h3>
                          <Row>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Funding Type
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <select
                                className="form-select"
                                id="fundingRound"
                                {...fundingRoundsForm.register("fundingType", {
                                  required: "Funding round is required",
                                })}
                                placeholder="Select Funding Round"
                              >
                                <option value="" disabled selected hidden>
                                  Select Funding Round
                                </option>
                                <option value="Pre-Seed">Pre-Seed</option>
                                <option value="Seed">Seed</option>
                                <option value="Series A">Series A</option>
                                <option value="Series B">Series B</option>
                                <option value="Series C">Series C</option>
                                <option value="Series D">Series D</option>
                                <option value="Series E">Series E</option>
                                <option value="Series F">Series F</option>
                                <option value="Series G">Series G</option>
                                <option value="Series H">Series H</option>
                                <option value="Series I">Series I</option>
                                <option value="Series J">Series J</option>
                                <option value="Venture - Series Unknown">
                                  Venture - Series Unknown
                                </option>
                                <option value="Angel">Angel</option>
                                <option value="Private Equity">
                                  Private Equity
                                </option>
                                <option value="Debt Financing">
                                  Debt Financing
                                </option>
                                <option value="Convertible Note">
                                  Convertible Note
                                </option>
                                <option value="Grant">Grant</option>
                                <option value="Corporate Round">
                                  Corporate Round
                                </option>
                                <option value="Equity Crowdfunding">
                                  Equity Crowdfunding
                                </option>
                                <option value="Product Crowdfunding">
                                  Product Crowdfunding
                                </option>
                                <option value="Secondary Market">
                                  Secondary Market
                                </option>
                                <option value="Post-IPO Equity">
                                  Post-IPO Equity
                                </option>
                                <option value="Post-IPO Debt">
                                  Post-IPO Debt
                                </option>
                                <option value="Post-IPO Secondary">
                                  Post-IPO Secondary
                                </option>
                                <option value="Non-equity Assistance">
                                  Non-equity Assistance
                                </option>
                                <option value="Initial Coin Offering">
                                  Initial Coin Offering
                                </option>
                                <option value="Funding Round">
                                  Funding Round
                                </option>
                              </select>
                              {fundingRoundsForm.formState?.errors
                                ?.fundingType && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Announced Date
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                {...fundingRoundsForm.register("announcedDate")}
                                placeholder="Enter Announced Date"
                              />
                              {fundingRoundsForm.formState?.errors
                                ?.announcedDate && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Closed Date
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                {...fundingRoundsForm.register("closedDate")}
                                placeholder="Enter Closed Date"
                              />
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Money Raised
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                {...fundingRoundsForm.register("moneyRaised")}
                                placeholder="Enter Money Raised"
                              />
                              {fundingRoundsForm.formState?.errors
                                ?.moneyRaised && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Target Funding
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...fundingRoundsForm.register("targetFunding")}
                                placeholder="Enter Target Funding"
                              />
                              {fundingRoundsForm.formState?.errors
                                ?.targetFunding && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Pre-Money Valuation
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                {...fundingRoundsForm.register(
                                  "preMoneyValuation"
                                )}
                                placeholder="Enter Pre-Money Valuation"
                              />
                              {fundingRoundsForm.formState?.errors
                                ?.valuation && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Currency<span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...fundingRoundsForm.register("currency")}
                                placeholder="Enter Currency"
                              />
                              {fundingRoundsForm.formState?.errors
                                ?.currency && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </Col>
                      </Form>
                    </TabPane>
                    <TabPane
                      className={`${
                        show === "Acquisitions" ? "d-block" : "d-none"
                      } fade row show`}
                      id="confirm-info"
                    >
                      <Form>
                        <Col sm="12">
                          <h3 className="mb-4">Edit New Acquisition</h3>
                          <Row>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>Acquirer</Form.Label>
                              <Form.Control
                                type="text"
                                {...acquisitionsForm.register("acquirerId", {
                                  required: true,
                                })}
                                placeholder="Search Acquirer"
                              />
                              {acquisitionsForm.formState?.errors
                                ?.acquirerId && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>Acquiree</Form.Label>
                              <Form.Control
                                type="text"
                                {...acquisitionsForm.register("acquireeId", {
                                  required: true,
                                })}
                                placeholder="Search Acquiree"
                              />
                              {acquisitionsForm.formState?.errors
                                ?.acquireeId && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Announced Date
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                {...acquisitionsForm.register("announcedDate", {
                                  required: true,
                                })}
                                placeholder="Enter Announced Date"
                              />
                              {acquisitionsForm.formState?.errors
                                ?.announcedDate && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Completed on Date
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                {...acquisitionsForm.register(
                                  "completedOnDate"
                                )}
                                placeholder="Enter Completed on Date"
                              />
                              {acquisitionsForm.formState?.errors
                                ?.completedOnDate && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Acquisition Type
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...acquisitionsForm.register(
                                  "acquisitionType"
                                )}
                                placeholder="Enter Acquisition Type"
                              />
                              {acquisitionsForm.formState?.errors
                                ?.acquisitionType && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Price<span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="number"
                                {...acquisitionsForm.register("price")}
                                placeholder="Enter Price"
                              />
                              {acquisitionsForm.formState?.errors?.price && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Acquisition Status
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...acquisitionsForm.register(
                                  "acquisitionStatus"
                                )}
                                placeholder="Enter Acquisition Status"
                              />
                              {acquisitionsForm.formState?.errors
                                ?.acquisitionStatus && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Disposition Of Acquired Organization
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...acquisitionsForm.register(
                                  "dispositionOfAcquiredOrganization"
                                )}
                                placeholder="Enter Disposition Of Acquired Organization"
                              />
                              {acquisitionsForm.formState?.errors
                                ?.dispositionOfAcquiredOrganization && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </Col>
                      </Form>
                    </TabPane>
                    <TabPane
                      className={`${
                        show === "Events" ? "d-block" : "d-none"
                      } fade row show`}
                      id="confirm-info"
                    >
                      <Form>
                        <Col sm="12">
                          <h3 className="mb-4">Add New Events</h3>
                          <Row>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>Event Name</Form.Label>
                              <Form.Control
                                type="text"
                                {...eventsForm.register("name", {
                                  required: true,
                                })}
                                placeholder="Enter Event Name"
                              />
                              {eventsForm.formState?.errors?.name && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Event Type
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...eventsForm.register("eventType")}
                                placeholder="Enter Event Type"
                              />
                              {eventsForm.formState?.errors?.eventType && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Short Description
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...eventsForm.register("shortDescription")}
                                placeholder="Enter Short Description"
                              />
                              {eventsForm.formState?.errors
                                ?.shortDescription && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Event Start Date
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                {...eventsForm.register("eventStartDate", {
                                  required: true,
                                })}
                                placeholder="Enter Event Start Date"
                              />
                              {eventsForm.formState?.errors?.eventStartDate && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Event End Date
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                {...eventsForm.register("eventEndDate")}
                                placeholder="Enter Event End Date"
                              />
                              {eventsForm.formState?.errors?.eventEndDate && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Event URL<span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...eventsForm.register("eventUrl", {
                                  required: true,
                                })}
                                placeholder="Enter Event URL"
                              />
                              {eventsForm.formState?.errors?.eventUrl && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Banner Image URL
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...eventsForm.register("eventBannerImage")}
                                placeholder="Enter Banner Image URL"
                              />
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Event Registration URL
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...eventsForm.register("registrationUrl")}
                                placeholder="Enter Event Registration URL"
                              />
                              {eventsForm.formState?.errors
                                ?.registrationUrl && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Event Long Description
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                {...eventsForm.register("longDescription")}
                                placeholder="Enter Long Description"
                              />
                              {eventsForm.formState?.errors
                                ?.longDescription && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Address/Venue Location
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...eventsForm.register("addressId")}
                                placeholder="Search Address / Venue Location"
                              />
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Industry Type
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...eventsForm.register("industryTypeId")}
                                placeholder="Search Industry Type"
                              />
                            </Form.Group>
                          </Row>
                        </Col>
                      </Form>
                    </TabPane>
                    <TabPane
                      className={`${
                        show === "News" ? "d-block" : "d-none"
                      } fade row show`}
                      id="confirm-info"
                    >
                      <Form>
                        <Col sm="12">
                          <h3 className="mb-4">Add News / Press Release</h3>
                          <Row>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>News Title</Form.Label>
                              <Form.Control
                                type="text"
                                {...newsForm.register("title", {
                                  required: true,
                                })}
                                placeholder="Enter News Title"
                              />
                              {newsForm.formState?.errors?.title && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                News Link<span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...newsForm.register("link", {
                                  required: true,
                                })}
                                placeholder="Enter News Link"
                              />
                              {newsForm.formState?.errors?.link && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-6 form-group">
                              <Form.Label>
                                Banner Image URL
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                {...newsForm.register("newsBannerUrl", {
                                  required: true,
                                })}
                                placeholder="Enter Banner Image URL"
                              />
                              {newsForm.formState?.errors?.newsBannerUrl && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                              <Form.Label>
                                Description
                                <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                {...newsForm.register("description")}
                                placeholder="Enter Description"
                              />
                              {newsForm.formState?.errors?.description && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </Col>
                      </Form>
                    </TabPane>
                  </TabContent>
                  <div
                    className={`row ${
                      show !== "basic"
                        ? "justify-content-between"
                        : "flex-row-reverse"
                    }`}
                  >
                    {show !== "basic" && (
                      <div className="col-auto">
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setIndex(index - 1);
                            AccountShow(wizards[index - 1]);
                          }}
                        >
                          Previous
                        </Button>
                      </div>
                    )}
                    {show === "confirm" ? (
                      <div className="col-auto">
                        <Button
                          variant="primary"
                          onClick={() => {
                            submitMultipleForm();
                          }}
                        >
                          {loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    ) : (
                      <div className="col-auto">
                        <Button
                          variant="primary"
                          onClick={() => {
                            submitMultipleForm();
                          }}
                        >
                          {loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Save & Next"
                          )}
                        </Button>
                        {show !== "Overview" && (
                          <Button
                            variant="primary"
                            onClick={() => {
                              setIndex(index + 1);
                              AccountShow(wizards[index + 1]);
                            }}
                            className="ms-2"
                          >
                            Skip
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CreateCompany;
