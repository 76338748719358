import { configureStore } from "@reduxjs/toolkit";
import settingReducer from "./setting/reducers";
import authReducer from "./auth/auth";
import errorReducer from "./error/error";
import notificationReducer from "./notification/notification";

export const store = configureStore({
  reducer: {
    setting: settingReducer,
    auth: authReducer,
    error: errorReducer,
    notification: notificationReducer,
  },
});
