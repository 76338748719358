import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: null,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setGlobalNotification: (state, action) => {
      state.message = action.payload;
    },
    clearGlobalNotification: (state) => {
      state.message = null;
    },
  },
});

export const { setGlobalNotification, clearGlobalNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;

export const selectGlobalNotification = (state) => state.notification.message;
