import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authToken: null,
  userInfo: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.authToken = action.payload.authToken;
      state.userInfo = action.payload.userInfo;
    },
    clearAuth: (state) => {
      state.authToken = null;
      state.userInfo = null;
    },
  },
});

export const { setAuth, clearAuth } = authSlice.actions;

export default authSlice.reducer;

// Selectors
export const selectAuthToken = (state) => state.auth.authToken;
export const selectUserInfo = (state) => state.auth.userInfo;
