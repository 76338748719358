import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Form,
  Badge,
} from "react-bootstrap";
import Card from "../../../../components/Card";
import { Link, useNavigate } from "react-router-dom";
import pageimg1 from "../../../../assets/images/page-img/43.png";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthToken, selectUserInfo } from "../../../../store/auth/auth";
import axios from "axios";
import { setGlobalError } from "../../../../store/error/error";
import { setGlobalNotification } from "../../../../store/notification/notification";
import CreateEventDialog from "../../modals/create-event";

const ManageEvents = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const userObject = useSelector(selectUserInfo);
  const [searchKey, setSearchKey] = useState("");
  const authToken = useSelector(selectAuthToken);
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    if (authToken) {
      const fetchEventList = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/event`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          setEventData(response.data.data);
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      };
      fetchEventList();
    }
  }, [authToken]);

  const deleteEvent = async (event) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/event/${event.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // Locally Removing User From Records
      let eventDataCopy = [...eventData];
      eventDataCopy = eventDataCopy.filter((x) => x?.id != event.id);
      setEventData(eventDataCopy);
      dispatch(setGlobalNotification("Event deleted successfully !"));
      //
    } catch (error) {
      console.error("Error : ", error);
      dispatch(setGlobalError("Error ! Deleting Event."));
    }
  };

  return (
    <>
      <Row>
        <Col sm="24">
          <Card>
            <Card.Body className="pt-0">
              <Row className="justify-content-between d-flex">
                <Col sm="24" md="6">
                  <div
                    id="user_list_datatable_info"
                    className="dataTables_filter"
                  >
                    <form className="me-3 position-relative">
                      <div className="form-group mb-0">
                        <input
                          type="search"
                          className="form-control"
                          id="exampleInputSearch"
                          placeholder="Search Events"
                          value={searchKey}
                          onChange={(e) => setSearchKey(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </Col>
                <Col sm="24" md="6">
                  <div className="user-list-files d-flex justify-content-end">
                    <CreateEventDialog showTextButton={true} />
                    {/* <Link
                      to="#"
                      className="chat-icon-phone btn btn-primary-subtle"
                    >
                      Print
                    </Link>{" "}
                    <Link
                      to="#"
                      className="chat-icon-video btn btn-primary-subtle"
                    >
                      Excel
                    </Link>{" "}
                    <Link
                      to="#"
                      className="cchat-icon-delete btn btn-primary-subtle"
                    >
                      Pdf
                    </Link>{" "} */}
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <table className="files-lists table table-striped mt-4 text-center">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Event Type</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Event URL</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventData
                      .filter(
                        (x) =>
                          x?.name
                            ?.toLowerCase()
                            .includes(searchKey?.toLowerCase()) ||
                          x?.eventType
                            ?.toLowerCase()
                            .includes(searchKey?.toLowerCase())
                      )
                      .map((event) => (
                        <tr>
                          <td className="text-start">
                            <img
                              className="rounded-circle img-fluid avatar-40 me-2 object-fit"
                              src={
                                event && event.eventBannerImage
                                  ? event?.eventBannerImage
                                  : pageimg1
                              }
                              alt="profile"
                            />{" "}
                            {event?.name}
                          </td>
                          <td>
                            <Badge
                              bg=""
                              bsPrefix="badge border border-primary text-primary mt-2"
                            >
                              {event?.eventType}
                            </Badge>
                          </td>
                          <td>
                            {new Date(event?.eventStartDate).toDateString()}
                          </td>
                          <td>
                            {new Date(event?.eventEndDate).toDateString()}
                          </td>
                          <td>{event?.eventUrl}</td>
                          <td>
                            <div className="flex align-items-center list-user-action lh-1">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <Link to="#" onClick={() => deleteEvent(event)}  className="text-danger">
                                  <i className="material-symbols-outlined">
                                    delete
                                  </i>
                                </Link>
                              </OverlayTrigger>{" "}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ManageEvents;
