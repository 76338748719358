import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Popover,
  Form,
  Badge,
} from "react-bootstrap";
import Card from "../../../../components/Card";
import { Link, useNavigate } from "react-router-dom";
import pageimg1 from "../../../../assets/images/page-img/43.png";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthToken, selectUserInfo } from "../../../../store/auth/auth";
import axios from "axios";
import { setGlobalError } from "../../../../store/error/error";
import { setGlobalNotification } from "../../../../store/notification/notification";

const ManageUsers = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const userObject = useSelector(selectUserInfo);
  const [searchKey, setSearchKey] = useState("");
  const authToken = useSelector(selectAuthToken);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authToken) {
      const fetchUserList = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/user`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          setUserData(response.data.data);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      };
      fetchUserList();
    }
  }, [authToken]);

  const changeUserAdminApprovalStatus = async (user) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/user/approval/${user.id}`,
        {
          adminApproved: !user.adminApproved,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      dispatch(setGlobalNotification("Approval Status Updated !"));
    } catch (error) {
      console.error("Error : ", error);
      dispatch(setGlobalError("Error ! Updating User Approved Status Failed."));
    }
  };

  const changeUserMembershipType = async (user, membershipType) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/user/membership/${user.id}`,
        {
          membershipType: membershipType,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      dispatch(setGlobalNotification("Membership Status Updated !"));
    } catch (error) {
      console.error("Error : ", error);
      dispatch(setGlobalError("Error ! Updating User Membership Type Failed."));
    }
  };

  const deleteUser = async (user) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/user/${user.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // Locally Removing User From Records
      let userDataCopy = [...userData];
      userDataCopy = userDataCopy.filter((x) => x?.id != user.id);
      setUserData(userDataCopy);
      dispatch(setGlobalNotification("User deleted successfully !"));
      //
    } catch (error) {
      console.error("Error : ", error);
      dispatch(setGlobalError("Error ! Deleting User."));
    }
  };

  return (
    <>
      <Row>
        <Col sm="24">
          <Card>
            <Card.Body className="pt-0">
              <Row className="justify-content-between d-flex">
                <Col sm="24" md="6">
                  <div
                    id="user_list_datatable_info"
                    className="dataTables_filter"
                  >
                    <form className="me-3 position-relative">
                      <div className="form-group mb-0">
                        <input
                          type="search"
                          className="form-control"
                          id="exampleInputSearch"
                          placeholder="Search Users"
                          value={searchKey}
                          onChange={(e) => setSearchKey(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </Col>
                <Col sm="24" md="6">
                  {/* <div className="user-list-files d-flex justify-content-end">
                    <Link
                      to="#"
                      className="chat-icon-phone btn btn-primary-subtle"
                    >
                      Print
                    </Link>{" "}
                    <Link
                      to="#"
                      className="chat-icon-video btn btn-primary-subtle"
                    >
                      Excel
                    </Link>{" "}
                    <Link
                      to="#"
                      className="cchat-icon-delete btn btn-primary-subtle"
                    >
                      Pdf
                    </Link>{" "}
                  </div> */}
                </Col>
              </Row>
              <div className="table-responsive">
                <table className="files-lists table table-striped mt-4 text-center">
                  <thead>
                    <tr>
                      {/* <th scope="col">
                        <div className=" text-center">
                          <input type="checkbox" className="form-check-input" />
                        </div>
                      </th> */}
                      <th scope="col">Name</th>
                      <th scope="col">User Type</th>
                      <th scope="col">Email</th>
                      <th scope="col">Date Joined</th>
                      <th scope="col">Membership Type</th>
                      <th scope="col">Email Verified</th>
                      <th scope="col">Admin Approved</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData
                      .filter(
                        (x) =>
                          x?.firstName
                            ?.toLowerCase()
                            .includes(searchKey?.toLowerCase()) ||
                          x?.lastName
                            ?.toLowerCase()
                            .includes(searchKey?.toLowerCase()) ||
                          x?.email
                            ?.toLowerCase()
                            .includes(searchKey?.toLowerCase())
                      )
                      .map((user) => (
                        <tr>
                          {/* <td>
                            <div className=" text-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                              />
                            </div>
                          </td> */}
                          <td className="text-start">
                            <img
                              className="rounded-circle img-fluid avatar-40 me-2 object-fit"
                              src={
                                user && user.profilePictureUrl
                                  ? user?.profilePictureUrl
                                  : pageimg1
                              }
                              alt="profile"
                            />{" "}
                            {user?.firstName + " " + user?.lastName}
                          </td>
                          <td>
                            {user?.userType == 0 ? (
                              <Badge
                                bg=""
                                bsPrefix="badge border border-success text-success mt-2"
                              >
                                Registered
                              </Badge>
                            ) : (
                              <Badge
                                bg=""
                                bsPrefix="badge border border-danger text-danger mt-2"
                              >
                                Admin
                              </Badge>
                            )}
                          </td>
                          <td>{user?.email}</td>
                          <td>{new Date(user?.createdAt).toDateString()}</td>
                          <td>
                            <OverlayTrigger
                              trigger="click"
                              placement="left"
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <p className="mt-3">
                                      Change this user membership to:
                                      <div className="d-flex justify-content-around">
                                        <Badge
                                          bg=""
                                          bsPrefix="badge border border-success text-success m-1 mt-2 w-50 cursor-pointer"
                                          onClick={() => changeUserMembershipType(user, "0")}
                                        >
                                          Freemium
                                        </Badge>
                                        <Badge
                                          bg=""
                                          bsPrefix="badge border border-warning text-warning m-1 mt-2 w-50 cursor-pointer"
                                          onClick={() => changeUserMembershipType(user, "1")}
                                        >
                                          Gold
                                        </Badge>
                                      </div>
                                    </p>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {user?.membershipType == 0 ? (
                                <Badge
                                  bg=""
                                  bsPrefix="badge border border-success text-success mt-2"
                                >
                                  Freemium
                                </Badge>
                              ) : (
                                <Badge
                                  bg=""
                                  bsPrefix="badge border border-warning text-warning mt-2"
                                >
                                  Gold
                                </Badge>
                              )}
                            </OverlayTrigger>
                          </td>
                          <td>
                            <Form.Check className="form-check-inline me-2">
                              <Form.Check.Input
                                type="checkbox"
                                id="customCheck-f"
                                defaultChecked={
                                  user.emailVerified ? true : false
                                }
                              />
                            </Form.Check>
                          </td>
                          <td>
                            <Form.Check className="form-check-inline me-2">
                              <Form.Check.Input
                                type="checkbox"
                                id="customCheck-f"
                                defaultChecked={
                                  user.adminApproved ? true : false
                                }
                                onChange={() =>
                                  changeUserAdminApprovalStatus(user)
                                }
                              />
                            </Form.Check>
                          </td>
                          <td>
                            <div className="flex align-items-center list-user-action lh-1">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                              >
                                <Link to="#" onClick={() => deleteUser(user)}  className="text-danger">
                                  <i className="material-symbols-outlined">
                                    delete
                                  </i>
                                </Link>
                              </OverlayTrigger>{" "}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ManageUsers;
