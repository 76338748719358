import axios from "axios";
import { React, useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectAuthToken } from "../../../store/auth/auth";
import { setGlobalError } from "../../../store/error/error";

const CreateFAQDialog = ({ showTextButton, companyId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const authToken = useSelector(selectAuthToken);
  const handleClose = () => toggleDialog(false);
  const [loading, setLoading] = useState(false);
  const handleShow = () => toggleDialog(true);
  const newsForm = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    newsForm.reset();
  }, []);

  const onSubmit = async (payload) => {
    if (companyId) {
        payload.companyId = companyId;
      }
    if (!loading) {
      try {
        setLoading(true);
        await axios.post(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/faq`,
          payload,
          {
            headers: {
              Authorization: `${process.env.REACT_APP_AUTH_NAME} ${authToken}`,
            },
          }
        );
        handleClose(true);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        dispatch(setGlobalError("Error ! News Creation Faild."));
      }
    }
  };

  return (
    <>
      {showTextButton ? (
        <Button
          variant="primary"
          className="btn d-inline-flex btn-primary-subtle me-2 rounded-pill"
          onClick={handleShow}
        >
          <i className="material-symbols-outlined me-1">add</i>
          Faq
        </Button>
      ) : (
        <Link variant="outline-danger" to="#" onClick={handleShow}>
          Faq
        </Link>
      )}
      <Modal
        size="xl"
        show={showDialog}
        onHide={handleClose}
        style={{ display: "block" }}
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Col sm="12">
              <Row>
                <Form.Group className="col-md-12 form-group">
                  <Form.Label>Question</Form.Label>
                  <Form.Control
                    type="text"
                    {...newsForm.register("question", {
                      required: true,
                    })}
                    placeholder="Enter Question"
                  />
                  {newsForm.formState?.errors?.title && (
                    <span className="text-danger">This field is required</span>
                  )}
                </Form.Group>
                <Form.Group className="col-md-12 form-group">
                  <Form.Label>Answer</Form.Label>
                  <Form.Control
                    type="text"
                    {...newsForm.register("answer", {
                      required: true,
                    })}
                    placeholder="Enter Answer"
                  />
                  {newsForm.formState?.errors?.title && (
                    <span className="text-danger">This field is required</span>
                  )}
                </Form.Group>
              </Row>
            </Col>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={newsForm.handleSubmit(onSubmit)}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save changes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateFAQDialog;
