import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  globalError: null,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setGlobalError: (state, action) => {
      state.globalError = action.payload;
    },
    clearGlobalError: (state) => {
      state.globalError = null;
    },
  },
});

export const { setGlobalError, clearGlobalError } = errorSlice.actions;

export default errorSlice.reducer;

// Selector
export const selectGlobalError = (state) => state.error.globalError;
