import React from "react";
import { Container, Row, Col, Card, Image, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import loader from "../../../assets/images/page-img/page-load-loader.gif";

const Recommendations = () => {
  const sampleRecommendationData = [
    {
      title: "NVIDIA",
      description:
        "Nvidia Corporation is an American multinational corporation and technology company.",
      keyword: ["Growing", "High Chart"],
      logoURL:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyQwiWBNDFXtel_oOtA5wi6shry9D7vWBrtg&s",
      coverImageURL:
        "https://www.investopedia.com/thmb/FeirNkwX1agazM9CvqQ1EEWn82Q=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-1494623399-c8634676b26748c8a42b556cd9f6b822.jpg",
    },
    {
      title: "Exxon Mobil",
      description:
        "ExxonMobil Corporation is an American multinational oil and gas corporation.",
      keyword: ["Growing", "High Chart"],
      logoURL:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfrcYsGY9meDYasCBHWj0GFOoiTCTK0IEXgQ&s",
      coverImageURL:
        "https://www.offshore-technology.com/wp-content/uploads/sites/20/2022/02/shutterstock_1924499390-scaled.jpg",
    },
    {
      title: "Space X",
      description:
        "SpaceX is an American spacecraft manufacturer, launch service provider and satellite communications company",
      keyword: ["Growing", "High Chart"],
      logoURL:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAA8nUd4kDkigGGSt7I0ibutvW3EGK1AbOCvFAnXPoUqVznDh7Z0qzmlsMa9Zh-i8ls9c&usqp=CAU",
      coverImageURL:
        "https://media.licdn.com/dms/image/D5612AQEBeV6R8eCtNg/article-cover_image-shrink_720_1280/0/1693025056069?e=2147483647&v=beta&t=iWieRGjZ8WN4_Gcc9nREbarBhOaDuYABHCPOawQf5Do",
    },
  ];

  return (
    <>
      <div id="content-page" className="custom-container">
        <Row>
          <Col lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between rounded border-bottom-0">
                <div className="header-title">
                  <h4 className="card-title">Recommendation</h4>
                </div>
              </Card.Header>
            </Card>
          </Col>
          <Row className="align-items-center">
            {sampleRecommendationData.map((card) => (
              <Col md={4}>
                <div className="card card-block card-stretch card-height shadow-sm">
                  <div className="card-body profile-page p-0">
                    <div className="profile-header-image">
                      <div className="cover-container">
                        <img
                          loading="lazy"
                          src={card.coverImageURL}
                          alt="profile-bg"
                          className="rounded img-fluid w-100"
                          style={{height: '150px', objectFit: "cover"}}
                        />
                      </div>
                      <div className="profile-info p-4">
                        <div className="user-detail">
                          <div className="d-flex flex-wrap justify-content-between align-items-start">
                            <div className="profile-detail d-flex">
                              <div className="profile-img pe-lg-4">
                                <img
                                  loading="lazy"
                                  src={card.logoURL}
                                  alt="profile-img"
                                  className="avatar-80 img-fluid"
                                />
                              </div>
                              <div className="user-data-block mt-md-0 mt-2">
                                <h4>
                                  <Link to="/dashboard/app/friend-profile" className="font-size-18">
                                    {card.title}
                                  </Link>
                                </h4>
                                <h6>{card.keyword.join(", ")}</h6>
                                <p className="mb-2 mb-lg-0 font-size-12">
                                  {card.description}
                                </p>
                              </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                              Follow
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
            <div className="col-sm-12 text-center">
                      <img
                        src={loader}
                        alt="loader"
                        style={{ height: "100px" }}
                      />
                    </div>
          </Row>
        </Row>
      </div>
    </>
  );
};

export default Recommendations;
