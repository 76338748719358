import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Form,
  Badge,
  Button,
} from "react-bootstrap";
import Card from "../../../../components/Card";
import { Link, useNavigate } from "react-router-dom";
import pageimg1 from "../../../../assets/images/page-img/43.png";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthToken, selectUserInfo } from "../../../../store/auth/auth";
import axios from "axios";
import { setGlobalNotification } from "../../../../store/notification/notification";
import { setGlobalError } from "../../../../store/error/error";

const ManageOverallStats = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const userObject = useSelector(selectUserInfo);
  const authToken = useSelector(selectAuthToken);
  const [eventData, setEventData] = useState([]);
  const [statsId, setStatsId] = useState("");
  const [fundingRound, setFundingRound] = useState("");
  const [totalFunding, setTotalFunding] = useState("");
  const [aquisition, setAquisition] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (authToken) {
      const fetchMonthlyStats = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/general`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          setStatsId(response.data.data[0]?.id);
          setFundingRound(response.data.data[0]?.fundingRound);
          setTotalFunding(response.data.data[0]?.totalFunding);
          setAquisition(response.data.data[0]?.aquisition);
          setAmount(response.data.data[0]?.amount);
        } catch (error) {
          dispatch(setGlobalError("Error ! Cannot fetch overview stats."));
        }
      };
      fetchMonthlyStats();
    }
  }, [authToken]);

  const updateMonthlyStats = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_BASE_VERSION}/general/${statsId}`,
        {
          fundingRound: fundingRound,
          totalFunding: totalFunding,
          aquisition: aquisition,
          amount: amount,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      dispatch(
        setGlobalNotification("Monthly stats overview updated successfully !")
      );
    } catch (error) {
      console.error("Error fetching news:", error);
      dispatch(setGlobalError("Error ! Failed to update monthly stats"));
    }
  };

  return (
    <>
      <Row>
        <Col sm="24">
          <Card>
            <Card.Body className="pt-0">
              <Row className="justify-content-between d-flex mb-2">
                <Col sm="24" md="6">
                  <div
                    id="user_list_datatable_info"
                    className="dataTables_filter"
                  ></div>
                </Col>
                <Col sm="24" md="6">
                  {/* <div className="user-list-files d-flex justify-content-end">
                    <Link
                      to="#"
                      className="chat-icon-phone btn btn-primary-subtle"
                    >
                      Print
                    </Link>{" "}
                    <Link
                      to="#"
                      className="chat-icon-video btn btn-primary-subtle"
                    >
                      Excel
                    </Link>{" "}
                    <Link
                      to="#"
                      className="cchat-icon-delete btn btn-primary-subtle"
                    >
                      Pdf
                    </Link>{" "}
                  </div> */}
                </Col>
              </Row>
              <p>
                These statistics should be updated every month. They appears on
                user profile right side section
              </p>
              <Form>
                <Row>
                  <div className="col-md-6 mb-3">
                    <label>Funding Round</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Funding Round"
                      value={fundingRound}
                      onChange={(e) => setFundingRound(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Total Fundings</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Total Fundings"
                      value={totalFunding}
                      onChange={(e) => setTotalFunding(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Aquisition</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Aquisition"
                      value={aquisition}
                      onChange={(e) => setAquisition(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="col-auto">
                    <Button variant="primary" onClick={updateMonthlyStats}>
                      Update
                    </Button>
                  </div>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ManageOverallStats;
